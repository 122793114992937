import firebase from 'firebase.js';

const getFirestoreRef = (path) => firebase.firestore().collection(path);

const snapshotCollectionsItem = [];

export const fetchDocument = async (collection, id, callback = null) => {
  const baseQuery = getFirestoreRef(collection).doc(id);

  if (callback) {
    if (typeof snapshotCollectionsItem[collection] === 'undefined') {
      snapshotCollectionsItem[collection] = [];
    }

    if (!snapshotCollectionsItem[collection].includes(id)) {
      baseQuery.onSnapshot(docSnapshot => {
        let orderData = null;
        if (docSnapshot.exists)
          orderData = {id: docSnapshot.id, ...docSnapshot.data()};

        callback(orderData);
      }, err => {
        // eslint-disable-next-line
        console.log(`Encountered error: ${err}`);
      });

      snapshotCollectionsItem[collection].push(id);
    }
  }

  const document = await baseQuery.get();
  if (!document.exists) {
    return null;
  }

  return { id: document.id, ...document.data() };
};

const snapshotCollections = [];

export const fetchCollection = async (collection, options = {}, callback = null) => {
  const data = [];
  let baseQuery = getFirestoreRef(collection);

  if (options.queries) {
    const { queries } = options;
    queries.forEach(({ attribute, operator, value }) => {
      baseQuery = baseQuery.where(attribute, operator, value);
    });
  }

  if (options.sort) {
    const { sort } = options;

    sort.forEach(({ attribute, order }) => {
      baseQuery = baseQuery.orderBy(attribute, order);
    });
  }

  if (options.limit) {
    const { limit } = options;
    baseQuery = baseQuery.limit(limit);
  }

  if (callback && !snapshotCollections[collection] ) { 
    baseQuery.onSnapshot(querySnapshot => {
      const ordersData = querySnapshot.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      callback(ordersData);
    }, err => {
      // eslint-disable-next-line
      console.log(`Encountered error: ${err}`);
    });
    snapshotCollections[collection] = true;
  }

  (await baseQuery.get()).forEach((doc) =>
    data.push({ id: doc.id, ...doc.data() })
  );

  return data;
};

export const deleteDocument = (collection, id) => {
  return getFirestoreRef(collection).doc(id).delete();
};

export const createDocument = (collection, id, values) => {
  return getFirestoreRef(collection).doc(id).set(values);
};

export const addDocument = (collection, values) => {
  return getFirestoreRef(collection).add(values);
};

export const updateDocument = (collection, id, values) => {
  return getFirestoreRef(collection).doc(id).update(values);
};
