export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  COMPANIES: '/companies',
  ADD_COMPANY: '/companies/new',
  MODIFY_COMPANY: '/companies/:id',
  MEDIA: '/media',
  RESET_PASSWORD: '/recover-password',
};