/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { uploadFile } from 'state/actions/companies';
import ClipLoader from 'react-spinners/ClipLoader';
import classes from './DragAndDropUploadFiles.module.scss';

const DragAndDropUploadFiles = ({ closeModalHandle, folderPath }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleFile = async (files) => {
    const newArrayFiles = Array.from(files).map(item => {
      let validTypeFile = false;

      if(item.type.includes('image/') || item.type.includes('application/pdf')){
        validTypeFile = true;
      }

      return ({
        file: item,
        uploaded: false,
        error: validTypeFile !== true,
        processing: false,
        msg: validTypeFile ? '' : 'Type Error'
      });
    });

    setUploadedFiles(prevState => [...prevState, ...newArrayFiles]);
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onCloseModal = () => {
    setUploadedFiles(null);
    const refresh = uploadedFiles.length > 0;
    closeModalHandle(refresh);
  };

  const FileItem = ({ item }) => {
    const { uploaded, error, msg, processing, file:{name: fileName} } = item;

    useEffect(() => {
      if(!uploaded && !error && !processing){
        setUploadedFiles(prevState => {
          const newState = prevState.map(fileItem => {
            const newItem = fileItem;
            if(fileItem.file.name === fileName){
              newItem.processing = true;
            }
            return newItem;
          });
          return newState;
        });

        uploadFile(item.file, folderPath)
        .then(uploadResponse => {
          setUploadedFiles(prevState => {
            const newState = prevState.map(fileItem => {
              const newItem = fileItem;
              if(fileItem.file.name === fileName){
                newItem.msg = uploadResponse.msg;

                if(uploadResponse.status === 'success'){
                  newItem.uploaded = true;
                }
                else{
                  newItem.error = true;
                }
              }
              return newItem;
            });
            return newState;
          });
          
          return uploadResponse;
        })
        .catch((e) => {
          console.log(e);
        });
      }
    }, []);

    return (
      <div className="is-flex is-justify-content-space-between">
        <span>
          {fileName}
          {error && ( 
            <span> - <span className="has-text-danger">{msg}</span></span>
          )}
        </span>

        <div>
          {!error && !uploaded ? 
            <ClipLoader
              color="#36d7b7"
              loading
              size={18}
            />
          :
            <>
              {uploaded && !error ?
                <span className="icon green-icon">
                  <i className="mdi mdi-check default" />
                </span>
              :
                <span className="icon red-icon">
                  <i className="mdi mdi-close default" />
                </span>
              }
            </>
          }
        </div>
      </div>
    );
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"/>
      <div className="modal-content">
        <div className="box">
          <div className={classNames(classes.dragDropContainer, {[classes.active]: dragActive})} onDragEnter={handleDrag}>
            <input className="is-hidden" ref={inputRef} type="file" accept="image/*,application/pdf" multiple onChange={handleChange} />
            <label id="label-file-upload" htmlFor="input-file-upload">
              <div className="has-text-centered ">
                <p>Drag and drop your file here or</p>
                <button className="button is-primary" onClick={onButtonClick}>Upload a file</button>
              </div> 
            </label>
            { dragActive && <div className={classes.dragElement} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}/> }
          </div>
          {uploadedFiles.length > 0 &&
            <ul className="mt-2">
              { uploadedFiles.map(item => {
                  return (
                    <li key={`${item.file.name}-${Math.random()}`}>                    
                      <FileItem item={item} />
                    </li>
                  );
                })
              }
            </ul>
          }
        </div>
      </div>
      <button onClick={onCloseModal} className="modal-close is-large" aria-label="close"/>
    </div>
  );
};

export default DragAndDropUploadFiles;