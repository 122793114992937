/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'hooks';
import { Link } from 'react-router-dom';
import { fetchAllUsers, usersCleanUp, deleteUser, modifyUser } from 'state/actions/users';
import moment from 'moment';
import { checkAccess } from 'permissions';
import ROLES from 'permissions/roles';
import paths from 'pages/Router/paths';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import ConfirmationModal from 'components/ConfirmationModal';
import './CompanyUsers.scss';

const CompanyUsers = ({ companyId }) => {
  const { usersList, loading, authUser, deleted } = useSelector(
    (state) => ({
      usersList: state.users?.data?.filter(item => item.companies?.includes(companyId)),
      loading: state.users.loading,
      deleted: state.users.deleted,
      authUser: state.auth.userData
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    user: null,
    isOpen: false,
  });

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal({
        user: null,
        isOpen: false,
      });
    }
  }, [deleted, loading]);


  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUsers());

    return () => {
      dispatch(usersCleanUp());
    };
  }, [dispatch]);

  const userDeleteHandler = () => {
    const {id: userId, companies: userCompanies, role} = deleteModal.user;
    const filteredCompanies = userCompanies.filter(item => item !== companyId);
    const unassignedUser = { 
      ...deleteModal.user,
      companies: filteredCompanies
    };

    if(filteredCompanies.length > 0){
      dispatch(modifyUser(unassignedUser));
      setDeleteModal({ user: null, isOpen: false });
    }
    else if(role === ROLES.EMPLOYEE){
      dispatch(deleteUser(userId));
    }
    else{
      dispatch(modifyUser(unassignedUser));
      setDeleteModal({ user: null, isOpen: false });
    }
  };


  const onRemoveButtonClickHandler = (user) => {
    setDeleteModal((prevState) => ({
      user,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ user: null, isOpen: false });
  };

  const deleteMessage = useFormatMessage('Users.delete');
  const confirmMessage = useFormatMessage('Users.confirm');
  const permDeleteMessage = useFormatMessage('Users.permDelete');
  const cancelMessage = useFormatMessage('Users.cancel');
  const title = useFormatMessage('CompanyForm.companyUsers');
  const deleteBtn = useFormatMessage('CompanyForm.delete');
  const accessDeleteUser = checkAccess(`delete ${ROLES.EMPLOYEE} user`);
  const accessCreateUser = checkAccess(`create ${ROLES.EMPLOYEE} user`);

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={userDeleteHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <div className={classNames('tile is-ancestor', { 'users-loading': loading })}>
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-check default" />
                </span>
                {title}
              </p>
              {accessCreateUser &&
                <div className="field is-grouped card-header-icon">
                  <div className="control">
                    <Link 
                      to={{ pathname: paths.ADD_USER, state: { defaultValue: { role: [ROLES.EMPLOYEE], companies: [companyId]}, redirect: `/companies/${companyId}` }}} 
                      className="button">
                      {useFormatMessage('Users.newUser')}
                    </Link>
                  </div>
                </div>
              }
            </header>
            <div className="card-content">
              <table className="table">
                <thead>
                  <tr className="row">
                    <th>{useFormatMessage('Users.name')}</th>
                    <th>{useFormatMessage('Users.email')}</th>
                    <th>{useFormatMessage('Users.created')}</th>
                    <th>{useFormatMessage('Users.lastLogin')}</th>
                    <th>{useFormatMessage('Users.lastActivity')}</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList && usersList.length > 0 ? (
                    usersList.map((item) => {
                      const userUrl = authUser.id === item.id ? paths.PROFILE : `${paths.USERS}/${item.id}`;
                      
                      return (
                        <tr key={`${item.id}-${Date.now()}`}>
                          <td>
                            {authUser.role === ROLES.ADMIN || (authUser.role === ROLES.COMPANY_ADMIN && item.role.includes(ROLES.EMPLOYEE)) || (authUser.id === item.id) ? (
                              <Link to={userUrl}>
                                {item.username}
                              </Link>
                            )
                            :
                              item.username
                            }
                          </td>
                          <td>{item.email}</td>
                          <td>{moment(item.created).format("DD.MM.YYYY")}</td>
                          <td>{(item.last_login) ? moment(item.last_login).format("DD.MM.YYYY HH:mm") : ''}</td>
                          <td>{(item.last_activity) ? moment(item.last_activity).format("DD.MM.YYYY HH:mm") : ''}</td>
                          {accessDeleteUser &&
                            <td><button onClick={() => onRemoveButtonClickHandler(item)} className="button is-danger">{deleteBtn}</button></td>
                          }
                        </tr>
                      );
                    }))
                    : 
                    (
                      <tr>
                        <td>
                          <p>No users found</p>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyUsers;
