import { createReducer } from 'redux-act';

import {
  COMPANIES_FETCH_DATA_INIT,
  COMPANIES_FETCH_DATA_SUCCESS,
  COMPANIES_FETCH_DATA_FAIL,
  COMPANIES_DELETE_COMPANY_INIT,
  COMPANIES_DELETE_COMPANY_SUCCESS,
  COMPANIES_DELETE_COMPANY_FAIL,
  COMPANIES_CREATE_COMPANY_INIT,
  COMPANIES_CREATE_COMPANY_SUCCESS,
  COMPANIES_CREATE_COMPANY_FAIL,
  COMPANIES_MODIFY_COMPANY_INIT,
  COMPANIES_MODIFY_COMPANY_SUCCESS,
  COMPANIES_MODIFY_COMPANY_FAIL,
  COMPANIES_CLEAN_UP,
  COMPANIES_CLEAR_DATA_LOGOUT,
} from 'state/actions/companies';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  redirect: false
};

export const companiesReducer = createReducer(
  {
    [COMPANIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [COMPANIES_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [COMPANIES_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [COMPANIES_DELETE_COMPANY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COMPANIES_DELETE_COMPANY_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [COMPANIES_DELETE_COMPANY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COMPANIES_CREATE_COMPANY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COMPANIES_CREATE_COMPANY_SUCCESS]: (state, { company }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(company),
        loading: false,
        error: null,
        success: true,
        redirect: true
      });
    },
    [COMPANIES_CREATE_COMPANY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COMPANIES_MODIFY_COMPANY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COMPANIES_MODIFY_COMPANY_SUCCESS]: (state, { id, company, redirect = true }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  name: company.name,
                  id,
                  created: company.created,
                  address: company.address,
                  email: company.email,
                  phone: company.phone,
                  root_folder: company.root_folder,
                  access_token: company.access_token,
                  refresh_token: company.refresh_token,
                  google_account: company.google_account,
                  devices_limit: company.devices_limit,
                  user_root: company.user_root,
                  user_sub: company.user_sub,
                  storage: company.storage,
                  tags: company.tags,
                  reference_case: company.reference_case,
                  name_to_filename: company.name_to_filename,
                  trial: company.trial,
                  trial_expiration: company.trial_expiration,
                  filenameFormat: company.filenameFormat
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
        redirect
      });
    },
    [COMPANIES_MODIFY_COMPANY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COMPANIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
      redirect: false
    }),
    [COMPANIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
