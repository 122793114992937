/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, Fragment, useRef } from 'react';
import { useFormatMessage } from 'hooks';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { 
  getCompanyFiles,
  deleteCompanyFile,
  fetchCompanies,
  companiesCleanUp,
  addCompanyFolder,
  deleteCompanyFolder,
  renameCompanyFile
} from 'state/actions/companies';
import { fetchMedia, mediaCleanUp } from 'state/actions/media';
import MediaItem from 'components/MediaItem';
import ConfirmationModal from 'components/ConfirmationModal';
import ContentModal from 'components/ContentModal';
import DragAndDropUploadFiles from 'components/DragAndDropUploadFiles';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from 'components/Loader';
import ROLES from 'permissions/roles';
import moment from 'moment';
import { formatBytes } from 'utils';
import { checkAccess } from 'permissions';
import classNames from 'classnames';
import classes from './CompanyFiles.module.scss';

const CompanyFiles = ({ storagePath }) => {
  const { companies, mediaInfo } = useSelector(
    (state) => ({
      companies: state.companies.data.filter(item => item.storage === 'firebase_storage' && (state.auth.userData.role === ROLES.ADMIN || state.auth.userData.companies.includes(item.id))),
      mediaInfo: state.media.data
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if(!companies){
      dispatch(fetchCompanies());
    }
    
    dispatch(fetchMedia());

    return () => {
      dispatch(companiesCleanUp());
      dispatch(mediaCleanUp());
    };
  }, [dispatch]);

  const history = useHistory();
  const { hash } = useLocation();
  const [currentFiles, setCurrentFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(false);
  const [itemDeleted, setItemDeleted] = useState(true);
  const [fileRenamed, setFileRenamed] = useState(true);
  const [fileRenaming, setFileRenaming] = useState(false);
  const [folderCreated, setFolderCreated] = useState(true);
  const [fileMetadata, setFileMetadata] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [newFileName, setNewFileName] = useState('');

  const [openUploadFileModal, setOpenUploadFileModal] = useState(false);

  const [imgModal, setImgModal] = useState({
    content: null,
    isOpen: false,
    isLoading: false
  });
  const [deleteModal, setDeleteModal] = useState({
    item: null,
    isOpen: false,
  });

  const [renameModal, setRenameModal] = useState({
    item: null,
    isOpen: false,
  });

  const [addFolderModal, setAddFolderModal] = useState({
    isOpen: false,
  });

  const [nav, setNav] = useState([storagePath]);
  const { REACT_APP_SITE_API } = process.env;

  const encodeHash = (value) => {
    return decodeURIComponent(value.slice(1));
  };

  const getCurrentPath = () =>{
    let currentPath = storagePath;
    const hashPath = encodeHash(hash);
    if(hashPath){
      currentPath = hashPath;
    }
    else if(currentPath === 'Storage'){
      currentPath = '';
    }

    return currentPath;
  };

  const currentStoragePath = getCurrentPath();

  const getCurrentCompany = () => {
    let company = null;

    const currentStoragePathObj = currentStoragePath.split('/');
    if(currentStoragePathObj && currentStoragePathObj.length){
      let currentCompanyName = null;
      const zeroIndex = 0;
      const firstIndex = 1;
      if(currentStoragePathObj[zeroIndex] === 'Storage'){
        if(currentStoragePathObj[firstIndex]){
          currentCompanyName = currentStoragePathObj[firstIndex];
        }
      }
      else{
        currentCompanyName = currentStoragePathObj[zeroIndex];
      }
      
      if(currentCompanyName){
        company = companies.find(item => item.name === currentCompanyName);
      }
    }

    return company;
  };

  const currentCompany = getCurrentCompany();
  let currentStorageSize = 0;
  let currentStorageSizeInPercentage = 0;
  if(currentCompany){
    const currentCompanyFolderInfo = mediaInfo.find(item => item.name === currentCompany.name);
    if(currentCompanyFolderInfo){
      currentStorageSize = currentCompanyFolderInfo.size;
      currentStorageSizeInPercentage = (currentStorageSize/1000000) * 100;
    }
  }

  const openFolder = (path) => {
    const navItems = path === '' ? 'Storage' : path;
    setFilesLoading(true);

    setNav(navItems.split('/'));
    getCompanyFiles(path, companies)
    .then(res => setCurrentFiles(res))
    .finally(() => setFilesLoading(false));
  };

  useEffect(() => {
    let isMounted = true;
    const currentPath = getCurrentPath();

    const navItems = currentPath === '' ? 'Storage' : currentPath;
    setFilesLoading(true);

    setNav(navItems.split('/'));
    getCompanyFiles(currentPath, companies)
    .then(res => {
      if(isMounted)
        setCurrentFiles(res);
    })
    .finally(() => {
      if(isMounted)
        setFilesLoading(false);
    });

    setFileMetadata(null);

    return () => {
      isMounted = false;
    };
  }, [hash]);


  useEffect(() => {
    if (itemDeleted && !filesLoading) {
      setDeleteModal({
        item: null,
        isOpen: false,
      });
    }
  }, [itemDeleted, filesLoading]);

  useEffect(() => {
    if (fileRenamed && !filesLoading) {
      setRenameModal({
        item: null,
        isOpen: false,
      });
    }
  }, [fileRenamed, filesLoading]);

  useEffect(() => {
    if (folderCreated && !filesLoading) {
      setAddFolderModal({
        isOpen: false,
      });
    }
  }, [folderCreated, filesLoading]);

  const onRemoveItemClickHandler = (item) => {
    setItemDeleted(false);
    setDeleteModal((prevState) => ({
      item,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ filePath: null, isOpen: false });
  };

  const onDeleteItemHandler = () => {
    const currentPath = getCurrentPath();
    setItemDeleted(false);
    setFilesLoading(true);
    setFileMetadata(null);

    if(deleteModal.item.type === 'folder'){
      deleteCompanyFolder(deleteModal.item.fullPath)
      .then(() => openFolder(currentPath))
      .finally(() => {
        setItemDeleted(true);
        setFilesLoading(false);
      });
    }
    else{
      deleteCompanyFile(deleteModal.item.realPath)
      .then(() => openFolder(currentPath))
      .finally(() => {
        setItemDeleted(true);
        setFilesLoading(false);
      });
    }
  };

  const onCloseImgModalHandler = () => {
    setImgModal({ content: null, isOpen: false, isLoading: false });
  };

  const onLoadImage = () => {
    setImgModal((prevState) => ({
      content: prevState.content,
      isOpen: prevState.isOpen,
      isLoading: false
    }));
  };

  const handleClickToItem = (e, item) => {
    const eventAction = e.target.classList.contains('action-icon') 
    || e.target.classList.contains('mdi-action-icon') 
    || e.target.classList.contains('media-item-action-block') 
    || e.target.classList.contains('media-item-action-children');

    if(!eventAction){
      if(item.type === 'folder'){
        if(storagePath === 'Storage'){
          history.push(`#Storage/${encodeURIComponent(item.fullPath)}`);
        }
        else{
          history.push(`#${encodeURIComponent(item.fullPath)}`);
        }
      }
      else if(item.contentType === "application/pdf"){
        window.open(`${REACT_APP_SITE_API}/${item.fullPath}`, "_blank", "noreferrer");
      }
      else{
        const modalContent = (
          <div className={classNames({'is-block': !imgModal.isLoading}, classes.modalContentInner)}><img onLoad={onLoadImage} src={`${REACT_APP_SITE_API}/${item.fullPath}`} alt={`${item.name}`}/></div>
        );
        setImgModal((prevState) => ({
          content: modalContent,
          isOpen: !prevState.isOpen,
          isLoading: true
        }));
      }
    }
  };

  const onGetItemMetadata = (item) => {
    setFileMetadata(item);
  };

  const onCloseMetadata = () => {
    setFileMetadata(null);
  };

  const onChangeNewFolderName = (event) => {
    let currentFolderName = event.target.value;
    if(currentCompany && currentCompany.reference_case === "uppercase"){
      currentFolderName = currentFolderName.toUpperCase();
      
    }
    setNewFolderName(currentFolderName);    
  };

  const inputAddFolderRef = useRef();
  const inputAddFolder = <input onChange={onChangeNewFolderName} value={newFolderName} ref={inputAddFolderRef} className="input" name="folderName" type="text"/>;

  const handleAddFolder = () => {
    setFolderCreated(false);

    setAddFolderModal((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  const onAddFolderHandler = () => {
    if(newFolderName.length > 0){
      addCompanyFolder(`${currentStoragePath}/${newFolderName}`)
      .then(() => openFolder(currentStoragePath))
      .finally(() => {
        setFolderCreated(true);
        setNewFolderName('');
      });
    }
    else{
      inputAddFolderRef.current.classList.add("is-danger");
    }
  };

  const onCloseAddFolderModalHandler = () => {
    setAddFolderModal({ isOpen: false });
    setNewFolderName('');
  };

  const convertSize = (value, digits = 0) => {
    return Number.parseFloat(value).toFixed(digits);
  };

  const handleShowModalAddFile = () => {
    setOpenUploadFileModal(true);
  };

  const handleHideModalAddFile = (refresh = true) => {
    if(refresh)
      openFolder(currentStoragePath);
    
    setOpenUploadFileModal(false);
  };

  const onCloseRenameModalHandler = () => {
    setRenameModal({item: null, isOpen: false});
  };

  const onRenameItemClickHandler = (item) => {
    const fileName = item.name.split('.');
    setNewFileName(fileName[0]);

    setRenameModal((prevState) => ({
      item,
      isOpen: !prevState.isOpen,
    }));
  };

  const onRenameFile = (event) => {
    const currentFileName = event.target.value;
    setNewFileName(currentFileName);    
  };

  const inputRenameFileRef = useRef();
  const inputRenameFile = <input onChange={onRenameFile} value={newFileName} ref={inputRenameFileRef} className="input" name="fileName" type="text"/>;

  const onRenameFileHandler = () => {
    setFileRenamed(false);
    setFileRenaming(true);

    const oldFileName = renameModal.item.name.split('.')[0];
    if(newFileName.length > 0 && newFileName !== oldFileName){
      renameCompanyFile(newFileName, renameModal.item)
      .finally(() => {
        setFileRenamed(true);
        setFileRenaming(false);
        openFolder(currentStoragePath);
      });
    }
    else{
      inputRenameFileRef.current.classList.add("is-danger");
      setFileRenaming(false);
    }
  };

  const title = useFormatMessage('CompanyFiles.title');
  const metadataTitle = useFormatMessage('CompanyFiles.metadataTitle');
  const deleteMessage = useFormatMessage('CompanyFiles.delete');
  const confirmDeleteMessage = useFormatMessage('CompanyFiles.confirmDelete');
  const permDeleteMessage = useFormatMessage('CompanyFiles.permDelete');
  const cancelMessage = useFormatMessage('CompanyFiles.cancel');
  const uploadFile = useFormatMessage('CompanyFiles.uploadFile');
  const addFolder = useFormatMessage('CompanyFiles.addFolder');
  const createMessage = useFormatMessage('CompanyFiles.create');
  const metadataDetails = useFormatMessage('CompanyFiles.metadataDetails');
  const titleRenameFile = useFormatMessage('CompanyFiles.titleRenameFile');
  const renameFileMessage = useFormatMessage('CompanyFiles.renameFileMessage');

  const AccessEditMedia = checkAccess('edit media');

  return (
    <>
      {imgModal.isOpen && (
        <ContentModal
          isActive={imgModal.isOpen}
          body={imgModal.content}
          onCancel={onCloseImgModalHandler}
          isLoading={imgModal.isLoading}
        />
      )}

      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={filesLoading}
          confirmButtonMessage={deleteMessage}
          title={confirmDeleteMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteItemHandler}
          onCancel={onCloseModalHandler}
        />
      )}

      {renameModal.isOpen && (
        <ConfirmationModal
          isActive={renameModal.isOpen}
          isLoading={fileRenaming}
          confirmButtonMessage={renameFileMessage}
          title={titleRenameFile}
          body={inputRenameFile}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onRenameFileHandler}
          onCancel={onCloseRenameModalHandler}
        />
      )}

      {addFolderModal.isOpen && (
        <ConfirmationModal
          isActive={addFolderModal.isOpen}
          isLoading={filesLoading}
          confirmButtonMessage={createMessage}
          title={addFolder}
          body={inputAddFolder}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onAddFolderHandler}
          onCancel={onCloseAddFolderModalHandler}
        />
      )}

      {openUploadFileModal &&
        <DragAndDropUploadFiles closeModalHandle={handleHideModalAddFile} folderPath={currentStoragePath}/>
      }

      <div className={classNames('tile is-ancestor')}>      
        <div className="tile is-parent">
          <div className="card tile is-child">
            {filesLoading &&
              <div className={classes.loaderWrapper}>
                <Loader fullHeigth/>
              </div>
            }
            <header className="card-header">
              <div className="card-header-title">
                <div>
                  <span className="icon">
                    <i className="mdi mdi-file-image default" />
                  </span>
                  {title}
                </div>
              </div>
              <div className="card-header-icon is-flex-grow-1">
                { currentCompany &&
                  <>
                    <progress className="progress is-info is-medium mb-0 mr-1" value={currentStorageSizeInPercentage} max="100">{currentStorageSizeInPercentage}</progress>
                    <span className={classes.folderSize}>{convertSize(currentStorageSize, 2)}Mb of 1Tb</span>
                  </>
                }
              </div>
            </header>
            <div className="card-content">
              <div className="columns is-align-items-center">
                <div className="column">
                  <nav className="breadcrumb" aria-label="breadcrumbs">
                    <ul>
                      {nav &&
                        nav.map((navItem, index) => {
                          const url = nav.slice(0, index + 1).join('/');

                          return (
                            <Fragment key={navItem}>
                              {(index + 1) === nav.length ?
                                <li className="is-active"><a href="#" aria-current="page">{navItem}</a></li>
                              :
                                <li>
                                  <a href={`#${encodeURIComponent(url)}`}>{navItem}</a>
                                </li>
                              }
                            </Fragment>                            
                          );
                        })
                      }
                    </ul>
                  </nav>
                </div>
                {AccessEditMedia && currentStoragePath !== 'Storage' && currentStoragePath !== '' &&
                  <>
                    <div className="column is-narrow has-text-right-tablet">
                      <span 
                        onClick={handleShowModalAddFile}
                        className="button is-primary"
                      >
                        {uploadFile}
                      </span>
                    </div>
                    <div className="column is-narrow has-text-right-tablet">
                      <span 
                        onClick={handleAddFolder}
                        className="button is-primary"
                      >
                        {addFolder}
                      </span>
                    </div>
                  </>
                }
              </div>

              <div className="columns is-multiline is-mobile">
                {currentFiles && ((currentFiles.folder && currentFiles.folder.length > 0) || (currentFiles.files && currentFiles.files.length > 0) ) ? (
                  <>
                    {currentFiles.folder && currentFiles.folder.length > 0 &&
                      <div className="column is-12">
                        <div className="columns is-multiline is-mobile">
                          { currentFiles.folder.map((folder) => (
                            <MediaItem 
                              key={folder.name} 
                              item={folder} 
                              clickToItem={handleClickToItem} 
                              removeItem={onRemoveItemClickHandler}
                              renameItem={onRenameItemClickHandler}
                            />
                          ))}
                        </div>
                      </div>
                    }
                    {currentFiles.files && currentFiles.files.length > 0 &&
                      <div className="column is-12">
                        <div className="columns is-multiline is-mobile">
                          { currentFiles.files.map((file) => (
                            <MediaItem 
                              key={file.name} 
                              item={file} 
                              clickToItem={handleClickToItem} 
                              removeItem={onRemoveItemClickHandler} 
                              renameItem={onRenameItemClickHandler}
                              getMetadataItem={onGetItemMetadata}
                            />
                          ))}
                        </div>
                      </div>
                    }
                  </>
                )
                :
                  <div className="column is-12">Empty Folder</div>
                }
              </div>
            </div>
          </div>
        </div>
        {fileMetadata &&
          <div className="tile is-parent is-3">
            <div className="card tile is-child">
              <header className="card-header">
                <div className="card-header-title">
                  <div>
                    <span className="icon">
                      <i className="mdi mdi-information default" />
                    </span>
                    {metadataTitle}
                  </div>
                </div>
                <div onClick={onCloseMetadata} className="card-header-icon">
                  <span className="icon">
                    <i className="mdi mdi-close default" />
                  </span>
                </div>
              </header>
              <div className="card-content">
                <h3 className="subtitle is-6">{metadataDetails}</h3>
                <table className={classNames(classes.metaTable, "table")}>
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{fileMetadata.name}</td>
                    </tr>
                    <tr>
                      <th>Size</th>
                      <td>{formatBytes(fileMetadata.size)}</td>
                    </tr>
                    <tr>
                      <th>Created</th>
                      <td>
                        <span className="is-block">{moment(fileMetadata.timeCreated).format("MMM DD, YYYY")}</span>
                        <span>{moment(fileMetadata.timeCreated).format("ddd, hh:mmA [GMT]Z")}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Updated</th>
                      <td>
                        <span className="is-block">{moment(fileMetadata.updated).format("MMM DD, YYYY")}</span>
                        <span>{moment(fileMetadata.updated).format("ddd, hh:mmA [GMT]Z")}</span>
                      </td>
                    </tr>

                    {fileMetadata.customMetadata && fileMetadata.customMetadata['Image description'] ? (
                      <tr>
                        <th>Description</th>
                        <td>{fileMetadata.customMetadata['Image description']}</td>
                      </tr>
                    )
                    :
                      null
                    }

                    {fileMetadata.customMetadata && fileMetadata.customMetadata['Device Model'] ? (
                      <tr>
                        <th>Device Model</th>
                        <td>{fileMetadata.customMetadata['Device Model']}</td>
                      </tr>
                    )
                    :
                      null
                    }

                    {fileMetadata.customMetadata && fileMetadata.customMetadata['Device Type'] ? (
                      <tr>
                        <th>Device Type</th>
                        <td>{fileMetadata.customMetadata['Device Type']}</td>
                      </tr>
                    )
                    :
                      null
                    }

                    {fileMetadata.customMetadata && fileMetadata.customMetadata.Author ? (
                      <tr>
                        <th>Author</th>
                        <td>{fileMetadata.customMetadata.Author}</td>
                      </tr>
                    )
                    :
                      null
                    }

                    {fileMetadata.customMetadata && fileMetadata.customMetadata.Tags ? (
                      <tr>
                        <th>Tags</th>
                        <td>{fileMetadata.customMetadata.Tags}</td>
                      </tr>
                    )
                    :
                      null
                    }

                  </tbody>
                </table>              
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default CompanyFiles;