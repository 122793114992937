import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropTypes from 'prop-types';
import is from 'date-fns/locale/is';
import en from 'date-fns/locale/en-US';
import { shallowEqual, useSelector } from 'react-redux';

import './DatePicker.scss';

registerLocale('en', en);
registerLocale('is', is);

const dateFormat = (locale) => {
  switch (locale) {
    case 'en':
      return 'dd/MM/yy';
    case 'is':
      return 'dd/MM/yy';
    default:
      return 'dd-MM-yy';
  }
};

const DatePickerStyled = ({ disabled = false, date = '', onChange, minDate = '', placeholder = '', readOnly = false }) => {
  const onDateChangedHandler = (value) =>{
    onChange(value ? value.toDateString() : '');
  };

  const { locale } = useSelector(
    (state) => ({
      locale: state.preferences.locale,
    }),
    shallowEqual
  );

  return (
    <DatePicker
      disabled={disabled}
      locale={locale}
      dateFormat={dateFormat(locale)}
      selected={date}
      minDate={minDate}
      onChange={onDateChangedHandler}
      className="input"
      placeholderText={placeholder} 
      readOnly={readOnly}
    />
  );
};

DatePickerStyled.defaultProps = {
  date: '',
  minDate: ''
};

DatePickerStyled.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  onChange: PropTypes.func.isRequired,
};

export default DatePickerStyled;
