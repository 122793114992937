import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import NavLink from '../Link';

export const SubMenu = ({ label, children }) => {
  const [active, setActive] = useState(false);

  return (
    <li className={classNames({ 'is-active': active })}>
      <a
        className="has-icon has-dropdown-icon"
        onClick={() => setActive(!active)}
      >
        <span className="icon">
          <i className="mdi mdi-view-list" />
        </span>
        <span className="menu-item-label">{label}</span>
        <div className="dropdown-icon">
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                { 'mdi-minus': active },
                { 'mdi-plus': !active }
              )}
            />
          </span>
        </div>
      </a>
      <ul>{children}</ul>
    </li>
  );
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const Aside = ({ handleMobileToggle }) => {
  const usersMessage = useFormatMessage('Aside.users');
  const accessUserList = checkAccess('view user list');
  const accessCompaniesList = checkAccess('view companies list');
  const accessMedia = checkAccess('view media');
  
  return (
    <aside className="aside is-placed-left is-expanded">
      <div className="aside-tools">
        <div className="aside-tools-label">
          <span>
            <b>Ferlamyndir</b>
          </span>
        </div>
      </div>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          { accessUserList && (
            <li>
              <NavLink
                to={paths.USERS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-account-supervisor" />
                </span>
                <span className="menu-item-label">{usersMessage}</span>
              </NavLink>
            </li>
          )}
          { accessCompaniesList && (
            <li>
              <NavLink
                to={paths.COMPANIES}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-shopping" />
                </span>
                <span className="menu-item-label">{useFormatMessage('Aside.companies')}</span>
              </NavLink>
            </li>
          )}
          { accessMedia && (
            <li>
              <NavLink
                to={paths.MEDIA}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-folder-multiple-image" />
                </span>
                <span className="menu-item-label">{useFormatMessage('Aside.media')}</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </aside>
  );
};

Aside.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
};

export default Aside;
