/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import './Tooltip.scss';

const Tooltip = ({ content }) => {
  return (
    <div className="tooltip-component">
      <span className="icon">
        <i className="mdi mdi-help-circle default" />
      </span>
      <div className="tooltip-content">
        {content}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Tooltip;
