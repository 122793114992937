/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {useDroppable} from '@dnd-kit/core';
import classNames from 'classnames';

const Droppable = (props) => {
  const { children } = props;
  const {isOver, setNodeRef} = useDroppable({
    id: 'droppable-filename-format',
  });

  const newElement = React.cloneElement(children, {
    className: classNames(children.props.className, {'has-background-primary-light': isOver && !children.props.readOnly})
  });

  return (
    <div ref={setNodeRef}>
      { newElement }
    </div>
  );
};

export default Droppable;