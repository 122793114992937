import ROLES from 'permissions/roles';

export default {
	[ROLES.ADMIN]: [
		'view media',
		'edit media',
		'view user list',
		'view all users',
		'create user',
		`create ${ROLES.ADMIN} user`,
		`create ${ROLES.EMPLOYEE} user`,
		`create ${ROLES.COMPANY_ADMIN} user`,
		`create ${ROLES.MEDIA_VIEWER} user`,
		`create ${ROLES.MEDIA_EDITOR} user`,
		'edit user',
		`edit ${ROLES.ADMIN} user`,
		`edit ${ROLES.EMPLOYEE} user`,
		`edit ${ROLES.COMPANY_ADMIN} user`,
		`edit ${ROLES.MEDIA_VIEWER} user`,
		`edit ${ROLES.MEDIA_EDITOR} user`,
		`delete ${ROLES.ADMIN} user`,
		`delete ${ROLES.EMPLOYEE} user`,
		`delete ${ROLES.COMPANY_ADMIN} user`,
		`delete ${ROLES.MEDIA_VIEWER} user`,
		`delete ${ROLES.MEDIA_EDITOR} user`,
		'create company',
		'view companies list',
		'view company',
		'edit company',
		'generate token company',
		'delete company',
		'delete device'
	],
	[ROLES.COMPANY_ADMIN]: [
		'view media',
		'edit media',
		'view user list',
		`view all users`,
		'create user',
		`create ${ROLES.EMPLOYEE} user`,
		'edit user',
		`edit ${ROLES.EMPLOYEE} user`,
		`delete ${ROLES.EMPLOYEE} user`,
		'view companies list',
		'view company',
		'edit company',
		'generate token company',
		'delete device'
	],
	[ROLES.MEDIA_VIEWER]: [
		'view media'
	],
	[ROLES.MEDIA_EDITOR]: [
		'view media',
		'edit media'
	],
	[ROLES.EMPLOYEE]: []
};