import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import ROLES from 'permissions/roles';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import Table from 'components/Table';
import { fetchUsers, deleteUser, usersCleanUp, modifyUser } from 'state/actions/users';
import { fetchCompanies, companiesCleanUp } from 'state/actions/companies';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';

const Users = () => {
  const { usersList, userRole, loading, deleted, companies, authUserCompanies, authUserId } = useSelector(
    (state) => ({
      usersList: state.users.data.filter(item => state.auth.userData.role === ROLES.ADMIN || item?.companies?.find(companyItem => state.auth.userData.companies.includes(companyItem))),
      userRole: state.auth.userData.role,
      authUserCompanies: state.auth.userData.companies,
      authUserId: state.auth.userData.id,
      loading: state.users.loading,
      deleted: state.users.deleted,
      companies: state.companies.data
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    userId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState('');

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCompanies());

    return () => {
      dispatch(usersCleanUp());
      dispatch(companiesCleanUp());
    };
  }, [dispatch, userRole]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal({
        userId: null,
        isOpen: false,
      });
    }
  }, [deleted, loading]);


  const onRemoveButtonClickHandler = (userId) => {
    setDeleteModal((prevState) => ({
      userId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ userId: null, isOpen: false });
  };

  const onDeleteUserHandler = () => {
    if(userRole === ROLES.ADMIN){
      dispatch(deleteUser(deleteModal.userId));
    }
    else{
      const currentUser = usersList.find(item => item.id === deleteModal.userId);
      if(currentUser){
        const filteredCompanies = currentUser.companies?.filter(item => !authUserCompanies.includes(item));

        if(filteredCompanies.length > 0){
          const unassignedUser = { 
            ...currentUser,
            companies: filteredCompanies
          };
          dispatch(modifyUser(unassignedUser));
          setDeleteModal({ userId: null, isOpen: false });
        }
        else{
          dispatch(deleteUser(deleteModal.userId));
        }
      }
    }
  };

  const columns = [
    {
      Header: useFormatMessage('Users.name'),
      accessor: 'username',
      Cell: ({ row }) => {
        const userUrl = authUserId === row.original.id ? paths.PROFILE : `${paths.USERS}/${row.original.id}`;
        
        return (
          <>
            {checkAccess(`edit ${row.original.role} user`) || (authUserId === row.original.id) ? (
              <Link to={userUrl}>
                {row.original.username}
              </Link>
            )
            :
              row.original.username
            }
          </>
        );
      }
    },
    {
      Header: useFormatMessage('Users.email'),
      accessor: 'email',
    },
    {
      Header: useFormatMessage('Users.role'),
      accessor: 'role',
    },
    {
      Header: useFormatMessage('Users.companies'),
      accessor: 'companies',
      Cell: ({ row }) => {
        let userCompanies = [];

        if(row.original.companies && row.original.companies.length > 0 && companies && companies.length > 0 && checkAccess('view company')){
          let rowCompanies = [...row.original.companies];

          if(userRole !== ROLES.ADMIN){
            rowCompanies = row.original.companies.filter(userCompanyId => authUserCompanies && authUserCompanies.includes(userCompanyId));
          }

          userCompanies = rowCompanies.map(userCompanyId => {
            const companyLink = { 
              id: userCompanyId,
              title: userCompanyId,
            };

            const companyInfo = companies.find(companyItem => companyItem.id === userCompanyId);
            
            if(companyInfo){
              companyLink.title = companyInfo.name;
            }

            return companyLink;
          });
        }

        return (
          <>
            {userCompanies.length > 0 &&
              userCompanies.map((company) => 
                <div key={company.id}>
                  <Link to={`/companies/${company.id}`}>{company.title}</Link>
                </div>
              )}
          </>
        );
      }
    },
    {
      Header: useFormatMessage('Users.created'),
      accessor: 'created',
      Cell: ({ row }) => {
        let createdDate = row.original.created || '';
    
        if(createdDate)
          createdDate = moment(createdDate).format("DD.MM.YYYY");

        return createdDate;
      }
    },
    {
      Header: useFormatMessage('Users.lastLogin'),
      accessor: 'last_login',
      Cell: ({ row }) => {
        let lastLoginDate = row.original.last_login || '';
    
        if(lastLoginDate)
          lastLoginDate = moment(lastLoginDate).format("DD.MM.YYYY HH:mm");

        return lastLoginDate;
      }
    },
    {
      Header: useFormatMessage('Users.lastActivity'),
      accessor: 'last_activity',
      Cell: ({ row }) => {
        let lastActivityDate = row.original.last_activity || '';
    
        if(lastActivityDate)
          lastActivityDate = moment(lastActivityDate).format("DD.MM.YYYY HH:mm");

        return lastActivityDate;
      }
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          {checkAccess(`delete ${row.original.role} user`) && (authUserId !== row.original.id) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search || roleFilter
    ? usersList.filter((el) => {
        let result = false;

        const clonedElem = { ...el };

        delete clonedElem.id;
        delete clonedElem.role;
        delete clonedElem.companies;
        delete clonedElem.devices_limit;

        if(search && roleFilter){
          const searchEl = Object.values(clonedElem).some((field) => {
            if(field){
              return field.toLowerCase().includes(search.toLowerCase());
            }
            return false;
          });

          result = searchEl && el.role === roleFilter;
        }
        else if(search){
          result = Object.values(clonedElem).some((field) => {
            if(field){
              return field.toLowerCase().includes(search.toLowerCase());
            }

            return false;
          });
        }
        else if(roleFilter){
          result = el.role === roleFilter;
        }

        return result;
      })
    : usersList;

  const deleteMessage = useFormatMessage('Users.delete');

  const confirmMessage = useFormatMessage('Users.confirm');

  const permDeleteMessage = useFormatMessage('Users.permDelete');

  const cancelMessage = useFormatMessage('Users.cancel');

  const accessToAddUser = checkAccess('create user');

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteUserHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {accessToAddUser && (
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-left">
                <div className="level-item">
                  <h1 className="title">{useFormatMessage('Users.users')}</h1>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_USER} className="button">
                    {useFormatMessage('Users.newUser')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
              <div className="field is-horizontal mb-0 p-3">
                <div className="field-label is-normal">
                  <label htmlFor='inputSearch' className="label">{useFormatMessage('Users.search')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        id="inputSearch"
                        type="text"
                        className="input"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              { (userRole === ROLES.ADMIN || userRole === ROLES.COMPANY_ADMIN) &&
                <div className="field is-horizontal mb-0 p-3">
                  <div className="field-label is-normal">
                    <label htmlFor='inputSearch' className="label">{useFormatMessage('Users.role')}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select">
                          <select 
                            name="role"
                            onChange={(e) => setRoleFilter(e.target.value)}
                          >
                            <option key='all' value="">-All-</option>
                            { Object.values(ROLES).map((role) => (
                              <option key={role} value={role}>
                                {role}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </header>
          <div className="b-table">
            {loading ? <Loader /> : <Table columns={columns} data={data} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Users;
