import React, { useEffect, useMemo, useState } from 'react';
import { useParams, Redirect, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';
import ROLES from 'permissions/roles';
import UserForm from 'components/UserForm';
import UserDevices from 'components/UserDevices';
import { createUser, modifyUser, fetchUsers } from 'state/actions/users';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const User = () => {
  const { id } = useParams();
  const urlLocation = useLocation();
  const isEditing = useMemo(() => !!id, [id]);
  const [redirect, setRedirect] = useState('');

  const { userData, error } = useSelector(
    (state) => ({
      userData: state.users.data.find((user) => user.id === id),
      error: state.users.error
    }),
    shallowEqual
  );

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    username: yup.string().required(),
    role: yup.string().required(),
    companies: yup.array().when('role', {
      is: (value) => value === ROLES.EMPLOYEE || value === ROLES.COMPANY_ADMIN,
      then: (companySchema) => companySchema.min(1),
      otherwise: (companySchema) => companySchema.min(0)
    }),
    created: yup.string().required(),
    devices_limit: yup.number().transform( cv => Number.isNaN(cv) ? undefined : cv).positive().integer()
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!userData) {
        dispatch(fetchUsers(id));
      }
    }
  }, [isEditing, id, userData, dispatch]);


  if((isEditing && error))
    setRedirect(paths.USERS);
  
  const editUserMessage = useFormatMessage('User.editUser');
  const newUserMessage = useFormatMessage('User.newUser');

  const onSubmitHandler = async(value) => {
    const newValue = { ...value };
    const userCompanies = newValue?.companies?.map(userCompany => userCompany.value) || [];

    const newUser = {
      ...newValue,
      companies: userCompanies,
      isEditing,
      id,
    };

    if (isEditing) {
      const responseModify = await dispatch(modifyUser(newUser));
      if(responseModify.type === "USERS_MODIFY_USER_SUCCESS"){
        setRedirect(paths.USERS);
      }
    } else {
      const responseCreate = await dispatch(createUser(newUser));
      if(responseCreate.type === "USERS_CREATE_USER_SUCCESS"){
        if(urlLocation.state && urlLocation.state.redirect){
          setRedirect(urlLocation.state.redirect);
        }
        else{
          setRedirect(paths.USERS);
        }
      }
    }
  };

  return (
    <>
      {redirect && 
        <Redirect to={redirect} />
      }
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editUserMessage : newUserMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !userData ? (
          <Loader />
        ) : (
          <>
            <UserForm
              isEditing={isEditing}
              user={
                isEditing
                  ? userData
                  : {
                      username: '',
                      email: '',
                      devices_limit: '',
                      created: new Date().toISOString(),
                      role: urlLocation?.state?.defaultValue?.role || '',
                      companies: urlLocation?.state?.defaultValue?.companies || []
                    }
              }
              filterByRole={urlLocation?.state?.defaultValue?.role || null}
              onSubmitHandler={onSubmitHandler}
              schema={schema}
            />

            {isEditing &&
              <UserDevices userId={id} />
            }
          </>
        )}
      </section>
    </>
  );
};

export default User;
