import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from 'pages/Router/paths';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';

const Home = () => {
  const accessViewUserList = checkAccess('view user list');

  return (
    <>
      {accessViewUserList && 
        <Redirect to={paths.USERS} />
      }
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{useFormatMessage('Home.home')}</h1>
        </div>
      </section>

      <section className="section is-main-section">
        {useFormatMessage('Home.content')}
      </section>
    </>
  );
};

export default Home;
