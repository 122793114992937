import React, { useEffect, useMemo, useState} from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';
import CompanyForm from 'components/CompanyForm';
import CompanyUsers from 'components/CompanyUsers';
import CompanyFiles from 'components/CompanyFiles';
import { createCompany, modifyCompany, fetchCompanies, companiesCleanUp } from 'state/actions/companies';
import { fetchTags, tagsCleanUp, createTag, deleteTag } from 'state/actions/tags';
import paths from 'pages/Router/paths';
import { toastr } from 'react-redux-toastr';
import { useFormatMessage } from 'hooks';
import { format } from 'date-fns';
import { checkExpired } from 'utils';
import ROLES from 'permissions/roles';

const Company = () => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, redirect, companyData, tags, authUserRole} = useSelector(
    (state) => ({
      success: state.companies.success,
      redirect: state.companies.redirect,
      companyData: state.companies.data.find((company) => company.id === id),
      tags: state.tags.data.filter(tag => tag.company_id === id),
      authUserRole: state.auth.userData.role,
    }),
    shallowEqual
  );

  const parseDateString = (value) => {
    let dateFormat = '';
    if(value) {
      const date = new Date(value);
      dateFormat = format(date, "yyyy-MM-dd");
    }

    return dateFormat;
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    created: yup.string().required(),
    email: yup.string().required(),
    address: yup.string(),
    phone: yup.string(),
    root_folder: yup.string(),
    access_token: yup.string(),
    refresh_token: yup.string(),
    google_account: yup.string(),
    devices_limit: yup.number().required().min(0),
    user_root: yup.string(),
    user_sub: yup.string().required(),
    storage: yup.string().required(),
    reference_case: yup.string().required(),
    trial: yup.bool(),
    trial_expiration: yup.string(),
    name_to_filename: yup.bool(),
    filenameFormat: yup.string()
  });

  const dispatch = useDispatch();

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (isEditing) {
      if(!companyData){
        dispatch(fetchCompanies(id));
      }
      if(companyData) {
        setIsExpired(companyData.trial && checkExpired(companyData.trial_expiration));
      }
    }

    return () => dispatch(companiesCleanUp());
  }, [isEditing, id, companyData, dispatch]);

  useEffect(() => {
    if(!tags || (tags && tags.length === 0)){
      dispatch(fetchTags());
    }

    return () => dispatch(tagsCleanUp());
  }, [dispatch]);

  const pageRedirect = (success && redirect) && (
    <Redirect to={paths.COMPANIES} />
  );

  const newCompanyMessage = useFormatMessage('Company.newCompany');
  const editCompanyMessage = useFormatMessage('Company.editCompany');
  const trialExpiredMessage = useFormatMessage('Company.trialExpired');

  const createTags = async(items) => {
    const promises = [];

    items.forEach(el => {
      const newTag = {
        tag_name: el.value,
        company_id: id
      };

      promises.push(dispatch(createTag(newTag, false)));
    });

    await Promise.all(promises);
  };

  const deleteTags = async(items) => {
    const promises = [];

    items.forEach(el => {
      promises.push(dispatch(deleteTag(el.id, false)));
    });

    await Promise.all(promises);
  };

  const onSubmitHandler = (value) => {
    if(value.tags){
      const newTags = value.tags.filter(tag => !tags.find(item => item.tag_name === tag.value));
      if(newTags && newTags.length > 0){
        try{
          createTags(newTags);
        }
        catch{
          toastr.error('', 'Company Tags are not updated');
        }
      }

      const deletedTags = tags.filter(tag => !value.tags.find(item => item.value === tag.tag_name));
      if(deletedTags && deletedTags.length > 0){
        try{
          deleteTags(deletedTags);
        }
        catch{
          toastr.error('', 'Company Tags are not updated');
        }
      }
    }

    const newCompany = {
      ...value,
      trial_expiration: parseDateString(value.trial_expiration),
      id
    };

    delete newCompany.tags;

    if (isEditing) {
      dispatch(modifyCompany(newCompany));
    } else {
      dispatch(createCompany(newCompany));
    }
  };

  return (
    <>
      {pageRedirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {isEditing ? editCompanyMessage : newCompanyMessage}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !companyData ? (
          <Loader />
        ) : (
          <>

            {( !isExpired || authUserRole === ROLES.ADMIN ) &&
              <CompanyForm
                isEditing={isEditing}
                company={
                  isEditing
                    ? companyData
                    : {
                        name: '',
                        email: '',
                        address: '',
                        phone: '',
                        root_folder: 'Ferlamyndir',
                        access_token: '',
                        refresh_token: '',
                        google_account: '',
                        devices_limit: 1,
                        user_root: 'user_controlled',
                        user_sub: 'none',
                        storage: 'google_drive',
                        created: new Date().toISOString(),
                        tags: [],
                        reference_case: 'normal',
                        trial: false,
                        trial_expiration: '',
                        name_to_filename: false,
                        filenameFormat: '{project}-{datetime}'
                      }
                }
                onSubmitHandler={onSubmitHandler}
                schema={schema}
              />
            }

            {isEditing && ( !isExpired || authUserRole === ROLES.ADMIN ) &&
              <>
                <CompanyUsers companyId={id} />

                {companyData.storage === "firebase_storage" &&
                  <CompanyFiles storagePath={companyData.name} />
                }
              </>
            }

            {isEditing && ( isExpired && authUserRole !== ROLES.ADMIN ) &&
              <>
                {trialExpiredMessage}
              </>
            }

          </>
        )}
      </section>
    </>
  );
};

export default Company;
