import { createReducer } from 'redux-act';

import {
  DEVICES_FETCH_DATA_INIT,
  DEVICES_FETCH_DATA_SUCCESS,
  DEVICES_FETCH_DATA_FAIL,
  DEVICES_DELETE_DEVICE_INIT,
  DEVICES_DELETE_DEVICE_SUCCESS,
  DEVICES_DELETE_DEVICE_FAIL,
  DEVICES_CREATE_DEVICE_INIT,
  DEVICES_CREATE_DEVICE_SUCCESS,
  DEVICES_CREATE_DEVICE_FAIL,
  DEVICES_MODIFY_DEVICE_INIT,
  DEVICES_MODIFY_DEVICE_SUCCESS,
  DEVICES_MODIFY_DEVICE_FAIL,
  DEVICES_CLEAN_UP,
  DEVICES_CLEAR_DATA_LOGOUT,
} from 'state/actions/devices';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const devicesReducer = createReducer(
  {
    [DEVICES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [DEVICES_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [DEVICES_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [DEVICES_DELETE_DEVICE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DEVICES_DELETE_DEVICE_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [DEVICES_DELETE_DEVICE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DEVICES_CREATE_DEVICE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DEVICES_CREATE_DEVICE_SUCCESS]: (state, { device }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(device),
        loading: false,
        error: null,
        success: true,
      });
    },
    [DEVICES_CREATE_DEVICE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DEVICES_MODIFY_DEVICE_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [DEVICES_MODIFY_DEVICE_SUCCESS]: (state, { id, device }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  id,
                  device_id: device.device_id,
                  user_id: device.user_id
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [DEVICES_MODIFY_DEVICE_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [DEVICES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [DEVICES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
