/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import classNames from 'classnames';
import classes from './Loader.module.scss';

const Loader = ({ size=80, fullHeigth=false }) => {
  return (
    <div className={classNames(classes.wrapper, {[classes.fullHeigth]: fullHeigth})}>
      <div className={classNames(classes.loader)}>
        <MoonLoader color="#006837" size={size} />
      </div>
    </div>
  );
};

export default Loader;