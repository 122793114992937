import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { authReducer } from './auth';
import { usersReducer } from './users';
import { companiesReducer } from './companies';
import { devicesReducer } from './devices';
import { mediaReducer } from './media';
import { tagsReducer } from './tags';
import { preferencesReducer } from './preferences';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  companies: persistReducer(
    {
      key: 'companies',
      storage,
      blacklist: ['error', 'loading'],
    },
    companiesReducer
  ),
  devices: persistReducer(
    {
      key: 'devices',
      storage,
      blacklist: ['error', 'loading'],
    },
    devicesReducer
  ),
  media: persistReducer(
    {
      key: 'media',
      storage,
      blacklist: ['error', 'loading'],
    },
    mediaReducer
  ),
  tags: persistReducer(
    {
      key: 'tags',
      storage,
      blacklist: ['error', 'loading'],
    },
    tagsReducer
  ),
  toastr: toastrReducer,
});
