import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Loader from 'components/Loader';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import Table from 'components/Table';
import { fetchCompanies, companiesCleanUp, deleteCompany } from 'state/actions/companies';
import { fetchUsers, usersCleanUp } from 'state/actions/users';
import paths from 'pages/Router/paths';
import ROLES from 'permissions/roles';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Companies.module.scss';

const Companies = () => {
  const { companies, error, loading, deleted, users} = useSelector(
    (state) => ({
      companies: state.companies.data.filter(item => state.auth.userData.role === ROLES.ADMIN || state.auth.userData.companies.includes(item.id)),
      error: state.companies.error,
      loading: state.companies.loading,
      deleted: state.companies.deleted,
      users: state.users.data
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const [deleteModal, setDeleteModal] = useState({
    couponId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchCompanies());

    if(!users || users.length === 0){
      dispatch(fetchUsers());
    }

    return () => {
      dispatch(companiesCleanUp());
      dispatch(usersCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        companyId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);

  const onRemoveButtonClickHandler = (companyId) => {
    setDeleteModal((prevState) => ({
      companyId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ companyId: null, isOpen: false });
  };

  const onDeleteCompanyHandler = () => {
    dispatch(deleteCompany(deleteModal.companyId));
  };

  const columns = [
    {
      Header: useFormatMessage('Companies.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <>
          { checkAccess('view company') ? (
            <Link to={`/companies/${row.original.id}`}>
              { row.original.name }
            </Link>
            ) : row.original.name 
          }
        </>
      )
    },
    {
      Header: useFormatMessage('Companies.email'),
      accessor: 'email',
    },
    {
      Header: useFormatMessage('Companies.usersCount'),
      accessor: 'usersCount',
      Cell: ({ row }) => {
        const count = users?.reduce(
          (accumulator, userItem) => {
            return userItem?.companies?.includes(row.original.id) ? accumulator + 1 : accumulator;
          }, 0
        );

        return count;
      }
    },
    {
      Header: useFormatMessage('Companies.createdDate'),
      accessor: 'created',
      Cell: ({ row }) => (
        <>
          {moment(row.original.created).format("DD.MM.YYYY")}
        </>
      ),
    },
  ];

  const actionCell = {
    Header: 'Actions',
    id: 'actions',
    accessor: 'id',
    Cell: ({ row }) => (
      <>
        <div className="buttons is-right">
          <Link
            to={`/companies/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </Link>
          
          {checkAccess(`delete company`) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      </>
    ),
    disableSortBy: true,
  };

  if (checkAccess('view company')) columns.push(actionCell);

  const accessToAddCompany = checkAccess('create company');

  const deleteMessage = useFormatMessage('Companies.delete');
  const confirmMessage = useFormatMessage('Companies.confirm');
  const permDeleteMessage = useFormatMessage('Companies.permDelete');
  const cancelMessage = useFormatMessage('Companies.cancel');

  const data = search
    ? companies.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase())
        );
      })
    : companies;

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCompanyHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">{useFormatMessage('Companies.Companies')}</h1>
              </div>
            </div>
            {accessToAddCompany &&
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_COMPANY} className="button">
                    {useFormatMessage('Companies.newCompany')}
                  </Link>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Companies.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </p>
          </header>
          <div className="b-table">
            {loading ? <Loader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Companies;
