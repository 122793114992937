/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import {useDraggable} from '@dnd-kit/core';

const Draggable = (props) => {
  const { children, id } = props;

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id,
  });
  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  
  return (
    <span ref={setNodeRef} className="button is-info is-small is-rounded" style={style} {...listeners} {...attributes}>
      {children}
    </span>
  );
};

export default Draggable;