import { useSelector, shallowEqual } from 'react-redux';
import ROLES from 'permissions/roles';
import RULES from 'permissions/rules';
import paths from 'pages/Router/paths';

export const checkAccess = (rule = null, withRedirect = false) => {
  const superAdmin = 'developer@webcodingstudio.com';

  const accessOptions = {
    access: false,
    redirect: paths.ROOT
  };

  const { role, id, email } = useSelector(
    state => ({
      role: state.auth.userData.role,
      id: state.auth.userData.id,
      email: state.auth.userData.email,
    }),
    shallowEqual
  );

  if (id) {
    if (rule) {
      if (superAdmin === email) {
        accessOptions.access = true;
      }
      else if (role) {
        if (RULES[role].includes(rule))
          accessOptions.access = true;
      }
    }
    else{
      accessOptions.access = true;
    }
  }
  else{
    accessOptions.access = false;
    accessOptions.redirect = paths.LOGIN;
  }

  if (!withRedirect) {
    return accessOptions.access;
  }

  return accessOptions;

};

export const getRolesForUserList = (role) => {
  const roles = [];

  let userRules = null;
  if (role) {
    userRules = RULES[role];
  }

  if (userRules) {
    if (userRules.includes('view all users')) {
      roles.push('all');
    }
    else{
      Object.values(ROLES).forEach(value => {
        if (userRules.includes(`view ${value} users`)) {
          roles.push(value);
        }
        if (userRules.includes(`view own ${value} users`)) {
          roles.push(`OWN ${value}`);
        }
      });
    }
  }

  return roles;
};