import { createReducer } from 'redux-act';

import {
  TAGS_FETCH_DATA_INIT,
  TAGS_FETCH_DATA_SUCCESS,
  TAGS_FETCH_DATA_FAIL,
  TAGS_DELETE_TAG_INIT,
  TAGS_DELETE_TAG_SUCCESS,
  TAGS_DELETE_TAG_FAIL,
  TAGS_CREATE_TAG_INIT,
  TAGS_CREATE_TAG_SUCCESS,
  TAGS_CREATE_TAG_FAIL,
  TAGS_MODIFY_TAG_INIT,
  TAGS_MODIFY_TAG_SUCCESS,
  TAGS_MODIFY_TAG_FAIL,
  TAGS_CLEAN_UP,
  TAGS_CLEAR_DATA_LOGOUT,
} from 'state/actions/tags';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const tagsReducer = createReducer(
  {
    [TAGS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [TAGS_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [TAGS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [TAGS_DELETE_TAG_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAGS_DELETE_TAG_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [TAGS_DELETE_TAG_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAGS_CREATE_TAG_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAGS_CREATE_TAG_SUCCESS]: (state, { tag }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(tag),
        loading: false,
        error: null,
        success: true,
      });
    },
    [TAGS_CREATE_TAG_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAGS_MODIFY_TAG_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [TAGS_MODIFY_TAG_SUCCESS]: (state, { id, tag }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  id,
                  tag_id: tag.tag_id,
                  user_id: tag.user_id
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [TAGS_MODIFY_TAG_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [TAGS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [TAGS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
