/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import { checkAccess } from 'permissions';
import ROLES from 'permissions/roles';
import Select from "react-select";
import paths from 'pages/Router/paths';
import { usersCleanUp } from 'state/actions/users';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import ErrorMessage from 'components/ErrorMessage';
import UpdatePassword from 'components/UpdatePassword';
import { fetchCompanies, companiesCleanUp } from 'state/actions/companies';
import { checkExpired } from 'utils';
import './UserForm.scss';

const UserForm = ({ isEditing, isProfile, user, onSubmitHandler, schema, filterByRole }) => {
  const { loading, success, authUserRole, authUserCompanies, companies } = useSelector(
    (state) => ({
      loading: state.users.loading,
      success: state.users.success,
      authUserRole: state.auth.userData.role,
      authUserCompanies: state.auth.userData?.companies || [],
      companies: state.companies.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [companyOptions, setCompanyOptions] = useState([]);

  const defaultValues = {
    created: user.created || new Date().toDateString(),
    username: user.username || '',
    email: user.email || '',
    role: user.role || '',
    companies: user?.companies?.map((company) => ({label: companies.find(item => item.id === company).name, value: company})) || [],
    devices_limit: user.devices_limit || ''
  };

  const { register, handleSubmit, errors, control, watch, setValue, reset } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!companies || companies.length === 0) {
      dispatch(fetchCompanies());
    }

    return () => {
      dispatch(companiesCleanUp());
    };
  }, [dispatch]);

  useEffect(() => {
    if(companies){
      if(authUserRole === ROLES.COMPANY_ADMIN){
        if(authUserCompanies.length){
          let options = companies.filter(item => authUserCompanies.includes(item.id));
          options = options.filter((item) => {
            const isExpired = (item.trial === true) && checkExpired(item.trial_expiration);
            return !isExpired;
          });
          setCompanyOptions(options);
        }
      }
      else{
        setCompanyOptions(companies);
      }
    }

  }, [companies]);

  useEffect(() => {

    return () => dispatch(usersCleanUp());
  }, [dispatch, success, setValue]);

  useEffect(() => {
    reset(defaultValues);
  }, [user]);

  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');
  const goBackMessage = useFormatMessage('UserForm.goBack');

  const emailMessage = useFormatMessage('UserForm.email');
  const roleMessage = useFormatMessage('UserForm.role');

  const infoLabel = useFormatMessage('UserForm.userInfo');
  const previewLabel = useFormatMessage('UserForm.userPreview');
  const selectCompanyLabel = useFormatMessage('UserForm.company');

  const accessCreateAdmin = checkAccess(`create ${ROLES.ADMIN} user`);
  const accessCreateCompanyAdmin = checkAccess(`create ${ROLES.COMPANY_ADMIN} user`);
  const accessCreateEmployee = checkAccess(`create ${ROLES.EMPLOYEE} user`);
  const accessCreateMediaViewer = checkAccess(`create ${ROLES.MEDIA_VIEWER} user`);
  const accessCreateMediaEditor = checkAccess(`create ${ROLES.MEDIA_EDITOR} user`);

  let roles = [];

  if (accessCreateAdmin) roles.push(ROLES.ADMIN);
  if (accessCreateCompanyAdmin) roles.push(ROLES.COMPANY_ADMIN);
  if (accessCreateEmployee) roles.push(ROLES.EMPLOYEE);
  if (accessCreateMediaViewer) roles.push(ROLES.MEDIA_VIEWER);
  if (accessCreateMediaEditor) roles.push(ROLES.MEDIA_EDITOR);

  if(filterByRole){
    roles = roles.filter(item => filterByRole.includes(item));
  }

  let editableUser = true;
  if(authUserCompanies.length && authUserRole !== ROLES.ADMIN){
    if(user.companies && user.companies.length){
      const otherCompanies = user.companies.filter(item => !authUserCompanies.includes(item));
      if(otherCompanies.length)
        editableUser = false;
    }
  }

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                { infoLabel }
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {isEditing ? (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{emailMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="email"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{emailMessage}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className={classNames(`input`, {
                                'is-danger': errors.email,
                              })}
                              ref={register}
                              name="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.email && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage text={invalidEmailMessage} />
                        </div>
                      </div>
                    )}

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{useFormatMessage('UserForm.password')}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className={classNames(`input`, {
                                'is-danger': errors.password,
                              })}
                              type="password"
                              ref={register}
                              name="password"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.password && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage text={useFormatMessage('UserForm.invalidPassword')} />
                        </div>
                      </div>
                    )}

                  </>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="username"
                          id="username"
                          readOnly={editableUser ? '' : "readOnly"}
                          className={classNames('input', {
                            'is-danger': errors.username,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.username && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                {!isProfile && (
                  <>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        { roleMessage }
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          {editableUser ?
                            <select
                              name="role"
                              className={classNames('select', {
                                'is-danger': errors.role,
                              })}
                              ref={register}
                            >
                              { roles.map((role) => (
                                <option key={role} value={role}>
                                  {role}
                                </option>
                              ))}
                            </select>
                          :
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="input is-static"
                              name="role"
                              ref={register}
                            />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.role && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal" />
                      <div className="field-body">
                        <ErrorMessage />
                      </div>
                    </div>
                  )}
                  </>
                )}

                {isProfile && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{roleMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="role"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {editableUser &&
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{ selectCompanyLabel }</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <div className="select is-multiple is-block">
                              <Controller
                                as={Select}
                                options={companyOptions?.map((company) => ({value: company.id, label: company.name}))}
                                name="companies"
                                isClearable
                                isDisabled={isProfile && authUserRole !== ROLES.ADMIN}
                                isMulti
                                control={control}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.companies && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                }

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.devicesLimit')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={authUserRole !== ROLES.ADMIN}
                          name="devices_limit"
                          id="devices_limit"
                          className={classNames('input', {
                            'is-danger': errors.devices_limit,
                            'is-static': authUserRole !== ROLES.ADMIN
                          })}
                          ref={register}
                          type="number"
                          min="1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.devices_limit && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <input 
                  type="hidden"
                  name="created"
                  ref={register} />

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        {editableUser &&
                          <div className="control">
                            <button
                              type="submit"
                              className={`button is-primary ${
                                loading && 'is-loading'
                              }`}
                            >
                              <span>{useFormatMessage('UserForm.submit')}</span>
                            </button>
                          </div>
                        }
                        {authUserRole !== ROLES.EMPLOYEE &&
                          <div className="control">
                            <Link to={paths.USERS} className="button">
                              {goBackMessage}
                            </Link>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                { previewLabel }
              </p>
            </header>
            <div className="card-content">
              {!isEditing && (
                <div className="field">
                  <label className="label">{emailMessage}</label>
                  <div className="control is-clearfix">
                    <input
                      data-testid="email"
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={watch('email')}
                    />
                  </div>
                </div>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('username')}
                  />
                </div>
              </div>

              {!isProfile && (
                <div className="field">
                  <label className="label">
                    { roleMessage }
                  </label>
                  <div className="control is-clearfix">
                    <input
                      data-testid="role"
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={watch('role')}
                    />
                  </div>
                </div>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {moment(watch('created')).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { (authUserRole === ROLES.ADMIN && !isProfile && isEditing) && 
        <UpdatePassword id={user.id} />
      }
    </>
  );
};

export default UserForm;
