/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import moment from 'moment';
import { checkAccess } from 'permissions';
import paths from 'pages/Router/paths';
import ROLES from 'permissions/roles';
import { generateTokenCompany, updateTokenCompany } from 'state/actions/companies';
import { fetchTags, tagsCleanUp } from 'state/actions/tags';
import { useFormatMessage } from 'hooks';
import DatePicker from 'components/DatePicker';
import Tooltip from 'components/Tooltip';
import { DndContext } from '@dnd-kit/core';
import Droppable from 'components/Droppable';
import Draggable from 'components/Draggable';
import CreatableSelect from 'react-select/creatable';
import ErrorMessage from 'components/ErrorMessage';
import { useGoogleLogin } from '@react-oauth/google';

import './CompanyForm.scss';

const CompanyForm = ({ isEditing, company, onSubmitHandler, schema }) => {
  const { loading, success, authUserRole, tags } = useSelector(
    (state) => ({
      loading: state.companies.loading,
      success: state.companies.success,
      authUserRole: state.auth.userData.role,
      tags: state.tags.data.filter(tag => tag.company_id === company.id)
    }),
    shallowEqual
  );

  const predefinedTokens = ['project', 'date', 'datetime', 'username'];
  const defaultValueTokens = predefinedTokens.filter(tokenItem => !(company.filenameFormat && company.filenameFormat.includes(`{${tokenItem}}`)));

  const [filenameTokens, setFilenameTokens] = useState(defaultValueTokens);

  const initialEditState = () => {
    let initialState = {};

    if(!isEditing){
      initialState = Object.keys(company).reduce(
        (result, current) => {
          return {
            ...result,
            [current]: true,
          };
        },{}
      );
    }

    return initialState;
  };

  const [fieldsEditState, setFieldsEditState] = useState(initialEditState);

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues: { 
      ...company,
      trial_expiration: (company.trial_expiration) ? company.trial_expiration : '',
      tags: tags?.map((tag) => ({value: tag.tag_name, label: tag.tag_name}))
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if(company && success){
      if(company.access_token){
        setValue('access_token', company.access_token);
      }
      if(company.refresh_token){
        setValue('refresh_token', company.refresh_token);
      }
      if(company.google_account){
        setValue('google_account', company.google_account);
      }
    }
  }, [company]);


  useEffect(() => {
    if(!tags || (tags && tags.length === 0)){
      dispatch(fetchTags());
    }

    return () => dispatch(tagsCleanUp());
  }, [dispatch]);

  const editFieldHandler = (field) => {
    setFieldsEditState(current => ({...current, [field]: true}));
  };

  const goBackMessage = useFormatMessage('CompanyForm.goBack');
  const generateToken = useFormatMessage('CompanyForm.GenerateToken');
  const updateToken = useFormatMessage('CompanyForm.UpdateToken');

  const infoLabel = useFormatMessage('CompanyForm.CompanyInfo');
  const previewLabel = useFormatMessage('CompanyForm.CompanyPreview');

  const AccessToEditCompany = checkAccess('edit company');
  const AccessGenerateTokenCompany = checkAccess('generate token company');

  const refreshToken = watch('refresh_token');

  const generateTokenHandler = useGoogleLogin({
    scope: 'https://www.googleapis.com/auth/drive',
    flow: 'auth-code',
    onSuccess: (codeResponse) => {
      dispatch(generateTokenCompany(company.id, codeResponse.code));
    },
    onError: (error) => toastr.error('', `Login Failed: ${error}`)
  });

  const updateTokenHandler = () => {
    dispatch(updateTokenCompany(company.id, refreshToken));
  };

  const userRootOptions = [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'User controlled',
      value: 'user_controlled'
    },
    {
      label: 'Year',
      value: 'year'
    },
    {
      label: 'Year-Month',
      value: 'year_month'
    },
    {
      label: 'User',
      value: 'user'
    }
  ];

  const userSubReferenceOptions = [
    {
      label: 'None',
      value: 'none'
    },
    {
      label: 'Year-Month-Day',
      value: 'year_month_day'
    },
    {
      label: 'User Controlled',
      value: 'user_controlled'
    }
  ];

  const storageOptions = [
    {
      label: 'Google Drive',
      value: 'google_drive'
    },
    {
      label: 'Google Shared Drive',
      value: 'google_shared_drive'
    },
    {
      label: 'Firebase Storage',
      value: 'firebase_storage'
    }
  ];

  const referenceCaseOptions = [
    {
      label: 'Normal',
      value: 'normal'
    },
    {
      label: 'UPPERCASE',
      value: 'uppercase'
    }
  ];

  const trial = watch('trial');

  useEffect(() => {
    let trialExpirationDate = '';
    if(trial){
      trialExpirationDate = moment().add(30, 'days').format();
    }

    setValue('trial_expiration', trialExpirationDate);
  }, [trial]);


  const filenameFormat = watch('filenameFormat', '');

  useEffect(() => {
    const updatedTokens = predefinedTokens.filter(tokenItem => !filenameFormat.includes(`{${tokenItem}}`));
    setFilenameTokens(updatedTokens);
    
  }, [filenameFormat]);

  const handleDragEnd = (event) => {
    if (fieldsEditState.filenameFormat && event.over && event.over.id === 'droppable-filename-format') {
      const activeFilenameToken = event.active.id;
      setValue('filenameFormat', `${filenameFormat}{${activeFilenameToken}}`);

      setFilenameTokens((prevState) =>
        prevState.filter((prevItem) => prevItem !== activeFilenameToken)
      );
    }
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                { infoLabel }
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.name ? '' : 'readOnly'}
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("name")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.email')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.email ? '' : 'readOnly'}
                          name="email"
                          id="email"
                          className={classNames('input', {
                            'is-danger': errors.email,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("email")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.email && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.address')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.address ? '' : 'readOnly'}
                          name="address"
                          id="address"
                          className={classNames('input', {
                            'is-danger': errors.address,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("address")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.address && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.phone')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.phone ? '' : 'readOnly'}
                          name="phone"
                          id="phone"
                          className={classNames('input', {
                            'is-danger': errors.phone,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("phone")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.phone && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.devicesLimit')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.devices_limit && authUserRole === ROLES.ADMIN ? '' : 'readOnly'}
                          name="devices_limit"
                          id="devices_limit"
                          className={classNames('input', {
                            'is-danger': errors.devices_limit,
                          })}
                          ref={register}
                          type="number"
                          min="1"
                        />
                      </div>
                    </div>
                    {isEditing && authUserRole === ROLES.ADMIN &&
                      <span 
                        onClick={() => editFieldHandler("devices_limit")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.devices_limit && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">{ useFormatMessage('CompanyForm.tags') }</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select is-multiple is-block">
                          <Controller
                            as={CreatableSelect}
                            options={tags?.map((tag) => ({value: tag.tag_name, label: tag.tag_name}))}
                            name="tags"
                            isClearable
                            isMulti
                            isDisabled={!fieldsEditState.tags}
                            control={control}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: state.isDisabled ? '#f5f5f5' : '#fff',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("tags")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.tags && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.Storage')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select is-block">
                          <select
                            readOnly={fieldsEditState.storage ? '' : 'readOnly'}
                            name="storage"
                            id="storage"
                            className={classNames('input', {
                              'is-danger': errors.storage,
                            })}
                            ref={register}
                          >
                            { storageOptions.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("storage")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.storage && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.RootFolder')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.root_folder ? '' : 'readOnly'}
                          name="root_folder"
                          id="root_folder"
                          className={classNames('input', {
                            'is-danger': errors.root_folder,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("root_folder")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.root_folder && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.SharedDrive')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.shared_drive ? '' : 'readOnly'}
                          name="shared_drive"
                          id="shared_drive"
                          className={classNames('input', {
                            'is-danger': errors.shared_drive,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("shared_drive")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.shared_drive && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.UserRoot')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select is-block">
                          <select
                            readOnly={fieldsEditState.user_root ? '' : 'readOnly'}
                            name="user_root"
                            id="user_root"
                            className={classNames('input', {
                              'is-danger': errors.user_root,
                            })}
                            ref={register}
                          >
                            { userRootOptions.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("user_root")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.user_root && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.UserSub')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select is-block">
                          <select
                            readOnly={fieldsEditState.user_sub ? '' : 'readOnly'}
                            name="user_sub"
                            id="user_sub"
                            className={classNames('input', {
                              'is-danger': errors.user_sub,
                            })}
                            ref={register}
                          >
                            { userSubReferenceOptions.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("user_sub")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.user_sub && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.ReferenceCase')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select is-block">
                          <select
                            readOnly={fieldsEditState.reference_case ? '' : 'readOnly'}
                            name="reference_case"
                            id="reference_case"
                            className={classNames('input', {
                              'is-danger': errors.reference_case,
                            })}
                            ref={register}
                          >
                            { referenceCaseOptions.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("reference_case")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.reference_case && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.NameToFilename')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                         <label className="b-checkbox checkbox">
                            <input
                              readOnly={fieldsEditState.name_to_filename ? '' : 'readOnly'}
                              disabled={fieldsEditState.name_to_filename ? '' : 'disabled'}
                              type="checkbox"
                              name="name_to_filename"
                              id="name_to_filename"
                              className={classNames('input', {
                                'is-danger': errors.name_to_filename,
                              })}
                              ref={register}
                            />
                            <span className="check is-primary" />
                          </label>
                      </div>
                    </div>
                    <Tooltip content="First word from Name field value will be added to the file names at the end. Name will be lowercase and trimmed to 10 characters"/>
                    {isEditing &&
                      <span
                        onClick={() => editFieldHandler("name_to_filename")}
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.name_to_filename && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}


                <DndContext onDragEnd={handleDragEnd}>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('CompanyForm.filenameFormat')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <Droppable>
                            <input
                              readOnly={fieldsEditState.filenameFormat ? '' : 'readOnly'}
                              name="filenameFormat"
                              id="filenameFormat"
                              className={classNames('input', {
                                'is-danger': errors.filenameFormat,
                              })}
                              ref={register}
                              type="text"
                            />
                          </Droppable>
                          {filenameTokens && filenameTokens.length > 0 &&
                            <div className="token-container mt-2">
                              <div className="buttons">
                                {filenameTokens.map(tokenItem => (<Draggable key={tokenItem.toLowerCase()} id={tokenItem.toLowerCase()}>{tokenItem}</Draggable>))}
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      {isEditing &&
                        <span 
                          onClick={() => editFieldHandler("filenameFormat")} 
                          className="button">
                          {useFormatMessage('CompanyForm.editField')}
                        </span>
                      }
                    </div>
                  </div>
                </DndContext>
                {errors.filenameFormat && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}


                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.Trial')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                         <label className="b-checkbox checkbox">
                            <input
                              readOnly={fieldsEditState.trial && authUserRole === ROLES.ADMIN ? '' : 'readOnly'}
                              disabled={fieldsEditState.trial && authUserRole === ROLES.ADMIN ? '' : 'disabled'}
                              type="checkbox"
                              name="trial"
                              id="trial"
                              className={classNames('input', {
                                'is-danger': errors.trial,
                              })}
                              ref={register}
                            />
                            <span className="check is-primary" />
                          </label>
                      </div>
                    </div>
                    {isEditing && authUserRole === ROLES.ADMIN &&
                      <span
                        onClick={() => editFieldHandler("trial")}
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.trial && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage('CompanyForm.TrialExpiration')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <Controller
                              control={control}
                              name="trial_expiration"
                              render={({ onChange, name, value }) => (
                                <DatePicker
                                  name={name}
                                  onChange={onChange}
                                  readOnly={fieldsEditState.trial_expiration && authUserRole === ROLES.ADMIN ? '' : 'readOnly'}
                                  date={value ? new Date(value) : ''}
                                />
                              )}
                            />
                        </div>
                      </div>
                      {isEditing && authUserRole === ROLES.ADMIN &&
                        <span
                          onClick={() => editFieldHandler("trial_expiration")}
                          className="button">
                          {useFormatMessage('CompanyForm.editField')}
                        </span>
                      }
                    </div>
                  </div>
                
                {errors.trial_expiration && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                {isEditing ? (
                  <input 
                    type="hidden"
                    name="created"
                    ref={register} />
                ) 
                : 
                (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage('CompanyForm.created')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <Controller
                            control={control}
                            name="created"
                            render={({ onChange, name, value }) => (
                              <DatePicker
                                name={name}
                                onChange={onChange}
                                date={value ? new Date(value) : ''}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    {errors.created && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                  )
                }

                <hr />

                <h3 className="title is-6">Google Account Credentials</h3>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.GoogleAccount')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.google_account ? '' : 'readOnly'}
                          name="google_account"
                          id="google_account"
                          className={classNames('input', {
                            'is-danger': errors.google_account,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("google_account")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.google_account && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.AccessToken')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.access_token ? '' : 'readOnly'}
                          name="access_token"
                          id="access_token"
                          className={classNames('input', {
                            'is-danger': errors.access_token,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("access_token")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.access_token && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CompanyForm.RefreshToken')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          readOnly={fieldsEditState.refresh_token ? '' : 'readOnly'}
                          name="refresh_token"
                          id="refresh_token"
                          className={classNames('input', {
                            'is-danger': errors.refresh_token,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                    {isEditing &&
                      <span 
                        onClick={() => editFieldHandler("refresh_token")} 
                        className="button">
                        {useFormatMessage('CompanyForm.editField')}
                      </span>
                    }
                  </div>
                </div>
                {errors.refresh_token && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                { AccessGenerateTokenCompany &&
                  <div className="field is-horizontal">
                    <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            {refreshToken ?
                              <span
                                onClick={() => { if(!loading) updateTokenHandler();}}
                                className="button is-primary"
                                disabled={loading}
                              >
                                { updateToken }
                              </span>
                            :
                              <span
                                onClick={() => { if(!loading) generateTokenHandler();}}
                                className="button is-primary"
                                disabled={loading}
                              >
                                { generateToken }
                              </span>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }


                <hr />
                { AccessToEditCompany && 
                  <div className="field is-horizontal">
                    <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            <button
                              type="submit"
                              disabled={loading}
                              className={`button is-primary ${
                                loading && 'is-loading'
                              }`}
                            >
                              <span>{useFormatMessage('CompanyForm.submit')}</span>
                            </button>
                          </div>
                          <div className="control">
                            <Link to={paths.COMPANIES} className="button">
                              {goBackMessage}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                { previewLabel }
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">
                  {useFormatMessage('CompanyForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CompanyForm.email')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="email"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('email')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CompanyForm.address')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="address"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('address')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CompanyForm.phone')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="phone"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('phone')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CompanyForm.created')}
                </label>
                <div className="control is-clearfix">
                  <p className="date">
                    {moment(watch('created')).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CompanyForm.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    address: PropTypes.string,
    email: PropTypes.string,
    access_token: PropTypes.string,
    refresh_token: PropTypes.string,
    phone: PropTypes.string,
    devices_limit: PropTypes.number,
    user_root: PropTypes.string,
    user_sub: PropTypes.string,
    storage: PropTypes.string,
    reference_case: PropTypes.string,
    name_to_filename: PropTypes.bool,
    trial: PropTypes.bool,
    trial_expiration: PropTypes.string,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

export default CompanyForm;
