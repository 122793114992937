import React from 'react';
import CompanyFiles from 'components/CompanyFiles';
import { useFormatMessage } from 'hooks';

const Media = () => {
  const mediaTitle = useFormatMessage('Media.title');

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">
            {mediaTitle}
          </h1>
        </div>
      </section>
      <section className="section is-main-section">
        <CompanyFiles storagePath="Storage" />
      </section>
    </>
  );
};

export default Media;
