import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const TAGS_FETCH_DATA_INIT = createAction('TAGS_FETCH_DATA_INIT');
export const TAGS_FETCH_DATA_SUCCESS = createAction(
  'TAGS_FETCH_DATA_SUCCESS'
);
export const TAGS_FETCH_DATA_FAIL = createAction('TAGS_FETCH_DATA_FAIL');

export const TAGS_DELETE_TAG_INIT = createAction('TAGS_DELETE_TAG_INIT');
export const TAGS_DELETE_TAG_SUCCESS = createAction(
  'TAGS_DELETE_TAG_SUCCESS'
);
export const TAGS_DELETE_TAG_FAIL = createAction('TAGS_DELETE_TAG_FAIL');

export const TAGS_CREATE_TAG_INIT = createAction('TAGS_CREATE_TAG_INIT');
export const TAGS_CREATE_TAG_SUCCESS = createAction(
  'TAGS_CREATE_TAG_SUCCESS'
);
export const TAGS_CREATE_TAG_FAIL = createAction('TAGS_CREATE_TAG_FAIL');

export const TAGS_MODIFY_TAG_INIT = createAction('TAGS_MODIFY_TAG_INIT');
export const TAGS_MODIFY_TAG_SUCCESS = createAction(
  'TAGS_MODIFY_TAG_SUCCESS'
);
export const TAGS_MODIFY_TAG_FAIL = createAction('TAGS_MODIFY_TAG_FAIL');

export const TAGS_CLEAN_UP = createAction('TAGS_CLEAN_UP');

export const TAGS_CLEAR_DATA_LOGOUT = createAction('TAGS_CLEAR_DATA_LOGOUT');

export const fetchTags = (tagId = '') => {
  return async (dispatch, getState) => {
    dispatch(TAGS_FETCH_DATA_INIT());

    if (tagId) {
      let tag;
      try {
        tag = await fetchDocument('tags', tagId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(TAGS_FETCH_DATA_FAIL({ error }));
      }

      if (!tag) {
        const errorMessage = 'Tag not available';
        toastr.error('', errorMessage);
        return dispatch(TAGS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const tags = getState().tags.data;
      tags.push(tag);

      return dispatch(
        TAGS_FETCH_DATA_SUCCESS({
          data: tags
        })
      );
    }

    let tags;

    try {
      tags = await fetchCollection('tags');
    } catch (error) {
      return dispatch(TAGS_FETCH_DATA_FAIL({ error }));
    }

    if (!tags) {
      const errorMessage = 'Tags not found';
      toastr.info('', errorMessage);
      return dispatch(TAGS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      TAGS_FETCH_DATA_SUCCESS({
        data: tags
      })
    );
  };
};

export const deleteTag = (id, showMsg = true) => {
  return async (dispatch, getState) => {
    dispatch(TAGS_DELETE_TAG_INIT());

    const { locale } = getState().preferences;

    try {
      await deleteDocument('tags', id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      
      if(showMsg)
        toastr.error('', errorMessage);
      
      return dispatch(
        TAGS_DELETE_TAG_FAIL({
          error: errorMessage,
        })
      );
    }
    if(showMsg)
      toastr.success('', 'The tag was deleted.');
    
    return dispatch(TAGS_DELETE_TAG_SUCCESS({ id }));
  };
};

export const clearTagsDataLogout = () => {
  return (dispatch) => {
    dispatch(TAGS_CLEAR_DATA_LOGOUT());
  };
};


export const createTag = (data, showMsg = true) => {
  return async (dispatch, getState) => {
    dispatch(TAGS_CREATE_TAG_INIT());

    const { locale } = getState().preferences;
    const tagData = {...data};
    let response;

    try {
      response = await addDocument('tags', tagData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      if(showMsg)
        toastr.error('', errorMessage);
      
      return dispatch(
        TAGS_CREATE_TAG_FAIL({
          error: errorMessage,
        })
      );
    }

    if(showMsg)
      toastr.success('', 'Tag created successfully');

    return dispatch(TAGS_CREATE_TAG_SUCCESS({ tag: {
        id: response.id,
        ...tagData
      }
    }));
  };
};

export const modifyTag = (data) => {
  return async (dispatch, getState) => {
    dispatch(TAGS_MODIFY_TAG_INIT());

    const { locale } = getState().preferences;
    const { id } = data;

    const tagData = {...data};
    delete tagData.id;
    
    try {
      await updateDocument('tags', id, tagData);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        TAGS_MODIFY_TAG_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Tag updated successfully');
    return dispatch(TAGS_MODIFY_TAG_SUCCESS({ tag: tagData, id }));
  };
};

export const tagsCleanUp = () => (dispatch) => dispatch(TAGS_CLEAN_UP());