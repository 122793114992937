import { createIntl, createIntlCache } from 'react-intl';
import firebase from 'firebase.js';

import english from 'languages/en';
import icelandic from 'languages/is';
import en from 'assets/en.png';
import is from 'assets/is.png';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'auth/email-already-exists',
  EMAIL_INVALID: 'auth/invalid-email',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  PASSWORD_INVALID: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  QUOTA_EXCEEDED_STORAGE: 'storage/quota-exceeded',
  UNAUTHENTICATED_STORAGE: 'storage/unauthenticated',
  UNAUTHORIZED_STORAGE: 'storage/unauthorized',
};

export const ORDER_STATUS = {
  todo:'Todo',
  in_picking: 'In picking',
  dispatched: 'Dispatched',
  received: 'Received by service desk',
  ready: 'Ready',
  delivered: 'Delivered',
};

export const ORDER_ITEM_STATUS = {
  todo:'Todo',
  picked: 'Picked',
  missing: 'Missing',
  received: 'Received'
};

export const messages = {
  en: english,
  is: icelandic
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const firebaseError = (error, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
  is
};

export const uiConfig = (onSignInSuccessHandler, onSignInFailHandler) => {
  return {
    callbacks: {
      signInSuccessWithAuthResult: onSignInSuccessHandler,
      signInFailure: onSignInFailHandler,
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/home',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with Google',
        scopes: [
          'https://www.googleapis.com/auth/user.addresses.read',
          'https://www.googleapis.com/auth/userinfo.email',
        ],
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with Facebook',
        scopes: ['email'],
      },
      { provider: 'microsoft.com', fullLabel: 'Continue with Microsoft' },
    ],
  };
};

export const convertDateZeroHour = (value) => {
  const date = new Date(value);

  return date.setHours(0, 0, 0, 0);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const checkExpired = (value) => {
  let isExpired = false;
  if(value) {
    const date = new Date(value);
    const now = new Date();
    if (convertDateZeroHour(date) < convertDateZeroHour(now)) {
      isExpired = true;
    }
  }
  return isExpired;
};
