import React from 'react';
import Loader from 'components/Loader';
import './ContentModal.scss';

const ContentModal = ({
  isActive,
  body,
  onCancel,
  isLoading
}) => {
  const modifiers = isActive && 'is-active';

  return (
    <div className={`modal content-modal-container ${modifiers}`}>
      <div
        className="modal-background"
        onClick={onCancel}
      />
      <div className="modal-content">
        {isLoading &&
          <Loader />
        }
        {body}        
      </div>
    </div>
  );
};

export default ContentModal;