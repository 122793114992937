import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';

import { getRolesForUserList } from 'permissions';
import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { checkUserData, AUTH_UPDATE_USER_DATA } from './auth';
import {
  fetchCollection,
  fetchDocument,
  createDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const USERS_FETCH_DATA_INIT = createAction('USERS_FETCH_DATA_INIT');
export const USERS_FETCH_DATA_SUCCESS = createAction(
  'USERS_FETCH_DATA_SUCCESS'
);
export const USERS_FETCH_DATA_FAIL = createAction('USERS_FETCH_DATA_FAIL');

export const USERS_DELETE_USER_INIT = createAction('USERS_DELETE_USER_INIT');
export const USERS_DELETE_USER_SUCCESS = createAction(
  'USERS_DELETE_USER_SUCCESS'
);
export const USERS_DELETE_USER_FAIL = createAction('USERS_DELETE_USER_FAIL');

export const USERS_CREATE_USER_INIT = createAction('USERS_CREATE_USER_INIT');
export const USERS_CREATE_USER_SUCCESS = createAction(
  'USERS_CREATE_USER_SUCCESS'
);
export const USERS_CREATE_USER_FAIL = createAction('USERS_CREATE_USER_FAIL');

export const USERS_MODIFY_USER_INIT = createAction('USERS_MODIFY_USER_INIT');
export const USERS_MODIFY_USER_SUCCESS = createAction(
  'USERS_MODIFY_USER_SUCCESS'
);
export const USERS_MODIFY_USER_FAIL = createAction('USERS_MODIFY_USER_FAIL');

export const USERS_UPDATE_USER_PASSWORD_INIT = createAction('USERS_UPDATE_USER_PASSWORD_INIT');
export const USERS_UPDATE_USER_PASSWORD_SUCCESS = createAction(
  'USERS_UPDATE_USER_PASSWORD_SUCCESS'
);
export const USERS_UPDATE_USER_PASSWORD_FAIL = createAction('USERS_UPDATE_USER_PASSWORD_FAIL');

export const USERS_CLEAN_UP = createAction('USERS_CLEAN_UP');

export const USERS_CLEAR_DATA_LOGOUT = createAction('USERS_CLEAR_DATA_LOGOUT');

export const fetchAllUsers = () => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(USERS_FETCH_DATA_INIT());

    const { id } = getState().auth.userData;

    let users;

    const observeUsers = (updatedUsers) => {
      return dispatch(
        USERS_FETCH_DATA_SUCCESS({
          data: updatedUsers.filter((user) => user.id !== id),
        })
      );
    };

    try {
      const queryOptions = {queries: []};
      users = await fetchCollection('users', queryOptions, observeUsers );
    } catch (error) {
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    if (!users) {
      const errorMessage = 'Users not found';
      toastr.info('', errorMessage);
      return dispatch(USERS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        data: users,
      })
    );
  };
};

export const fetchUsers = (userId = '') => {
  return async (dispatch, getState) => {
    dispatch(checkUserData());

    dispatch(USERS_FETCH_DATA_INIT());

    const observeUser = (updatedUser) => {
      if(!updatedUser){
        return dispatch(
          USERS_DELETE_USER_SUCCESS({
            id: userId
          })
        );
      }

      return dispatch(
        USERS_MODIFY_USER_SUCCESS({
          id: userId,
        })
      );
    };

    if (userId) {
      let user;
      try {
        user = await fetchDocument('users', userId, observeUser);
      } catch (error) {
        toastr.error('', error);
        return dispatch(USERS_FETCH_DATA_FAIL({ error }));
      }

      if (!user) {
        const errorMessage = 'User not available';
        toastr.error('', errorMessage);
        return dispatch(USERS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const users = getState().users.data;
      users.push(user);

      return dispatch(
        USERS_FETCH_DATA_SUCCESS({
          data: users,
        })
      );
    }

    const { id, role, companies } = getState().auth.userData;

    let users;

    let filterRoles = getRolesForUserList(role);

    const observeUsers = (updatedUsers) => {
      return dispatch(
        USERS_FETCH_DATA_SUCCESS({
          data: updatedUsers.filter((user) => user.id !== id),
        })
      );
    };

    try {
      const queryOptions = {queries: []};
      if (filterRoles.length) {
        if (!filterRoles.includes('all')) {

          const ownUserRoles = filterRoles.filter(item => item.includes('OWN'));
          filterRoles = filterRoles.filter(item => !ownUserRoles.includes(item));

          if (filterRoles.length) {
            queryOptions.queries.push({
              'attribute': 'role',
              'operator': 'in',
              'value': filterRoles
            });
          }

          if (ownUserRoles.length) {
            const filterCompanies = companies?.length ? companies : ['-1'];

            ownUserRoles.forEach((item) => {
              const itemRole = item.replace('OWN ', '');
              queryOptions.queries.push({
                'attribute': 'role',
                'operator': '==',
                'value': itemRole
              });

              queryOptions.queries.push({
                'attribute': 'companies',
                'operator': 'array-contains-any',
                'value': filterCompanies
              });
            });
          }
        }
      }

      users = await fetchCollection('users', queryOptions, observeUsers );
    } catch (error) {
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    if (!users) {
      const errorMessage = 'Users not found';
      toastr.info('', errorMessage);
      return dispatch(USERS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      USERS_FETCH_DATA_SUCCESS({
        data: users,
      })
    );
  };
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_DELETE_USER_INIT());
    const { locale } = getState().preferences;
    const deleteUserTask = deleteDocument('users', id);

    try {
      await Promise.all([deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_DELETE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The user was deleted.');
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
  };
};


export const createUser = ({
  username,
  email,
  password,
  created,
  role,
  companies = [],
  devices_limit: DEVICES_LIMIT = ''
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    const { locale } = getState().preferences;

    let response;
    try {
      const createUserAuth = firebase
        .functions()
        .httpsCallable('httpsCreateUser');

      response = await createUserAuth({ email, role, password });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    const { uid } = response.data;

    const userData = { username, email, created, role, companies, devices_limit: DEVICES_LIMIT };

    const createUserDbTask = createDocument('users', uid, userData);

    try {
      await Promise.all([createUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'User created successfully');
    return dispatch(USERS_CREATE_USER_SUCCESS({ user: {...userData, id: uid} }));
  };
};

export const modifyUser = ({
  username,
  created,
  role,
  id,
  isEditing,
  isProfile,
  companies = [],
  devices_limit: DEVICES_LIMIT = ''
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
   
    const userData = {
      username,
      created,
      role,
      companies,
      devices_limit: DEVICES_LIMIT
    };
    const updateUserDbTask = updateDocument('users', id, userData);

    try {
      await Promise.all([updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    const { uid } = firebase.auth().currentUser;

    if (id === uid) {
      dispatch(AUTH_UPDATE_USER_DATA({ ...userData, id }));
    }

    if (isProfile) {
      toastr.success('', 'Profile updated successfully');
    } else if (isEditing) {
      toastr.success('', 'User updated successfully');
    }

    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };
};

export const updateUserPass = ({
  id,
  password
}) => {
  return async (dispatch, getState) => {
    dispatch(USERS_UPDATE_USER_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      const updateUserPassword = firebase
        .functions()
        .httpsCallable('httpsUpdateUserPassword');

      await updateUserPassword({ id, password });
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_UPDATE_USER_PASSWORD_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'User password updated successfully');

    return dispatch(USERS_UPDATE_USER_PASSWORD_SUCCESS());
  };
};

export const updateUserLastLogin = (id, lastLogin) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
   
    const userData = {
      last_login: lastLogin
    };
    const updateUserDbTask = updateDocument('users', id, userData);

    try {
      await Promise.all([updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };
};

export const updateUserLastActivity = (id, lastActivity) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;
   
    const userData = {
      last_activity: lastActivity
    };
    const updateUserDbTask = updateDocument('users', id, userData);

    try {
      await Promise.all([updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };
};



export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());