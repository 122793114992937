import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { checkAccess } from 'permissions';
import noImageIcon from 'assets/no-image-icon.png';
import classes from './MediaItem.module.scss';

const MediaItem = ({ item, clickToItem, removeItem, renameItem, getMetadataItem }) => {
  const [showActionBlock, setShowActionBlock] = useState(false);
  const { REACT_APP_SITE_API } = process.env;
  const accessEditMedia = checkAccess('edit media');

  const useOutsideClick = (callback) => {
    const ref = useRef();

    useEffect(() => {
      const handleClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener('click', handleClick, true);

      return () => {
        document.removeEventListener('click', handleClick, true);
      };
    }, [ref]);

    return ref;
  };

  const handleClickOutsideActionBlock = () => {
    setShowActionBlock(false);
  };

  const ref = useOutsideClick(handleClickOutsideActionBlock);

  const clickActionIcon = () => {
    setShowActionBlock(!showActionBlock);
  };

  const onErrorLoadImg = (e) => {
    e.target.src = noImageIcon;
  };

  return (
    <div className="column is-12-mobile is-4-tablet is-3-widescreen is-2-fullhd">
      <div title={item.name} onClick={(e) => clickToItem(e,item)} className={classNames(classes.el, 'is-clickable')}>
        <div className={classNames('is-flex is-align-items-center is-justify-content-start', classes.elHeader)}>
          <span className="icon">
            {item.type === 'folder' ?
              <i className="mdi mdi-folder default" />
            :
              <i className="mdi mdi-file default" />
            }
          </span>
          <span className={classNames(classes.elNameWrap)}>
            <span className={classNames('is-size-7', classes.elName)}>{item.name}</span>
          </span>

          {accessEditMedia &&
            <>
              {(item.type === 'file' || (item.type === 'folder' && item.parent !== '')) && 
                <span onClick={clickActionIcon} className={classNames('icon action-icon', classes.elActionIcon)}>
                  <i className="mdi mdi-dots-vertical mdi-action-icon default" />
                </span>
              }
              {showActionBlock &&
                <div ref={ref} className={classNames('media-item-action-block', classes.actionBlock)}>
                  <div 
                    onClick={() => {
                      setShowActionBlock(false);
                      removeItem(item);
                    }} 
                    className={
                      classNames('media-item-action-children', classes.actionBlockItem)
                    }
                  >
                    <span className="icon media-item-action-children mr-1">
                      <i className="mdi mdi-delete default media-item-action-children" />
                    </span>
                    Remove
                  </div>
                  {item.type === 'file' &&
                    <>
                      <div 
                        onClick={() => {
                          setShowActionBlock(false);
                          getMetadataItem(item);
                        }} 
                        className={
                          classNames('media-item-action-children', classes.actionBlockItem)
                        }
                      >
                        <span className="icon media-item-action-children mr-1">
                          <i className="mdi mdi-information-outline default media-item-action-children" />
                        </span>
                        View Metadata
                      </div>
                      
                      <div 
                        onClick={() => {
                          setShowActionBlock(false);
                          renameItem(item);
                        }} 
                        className={
                          classNames('media-item-action-children', classes.actionBlockItem)
                        }
                      >
                        <span className="icon media-item-action-children mr-1">
                          <i className="mdi mdi-pencil default media-item-action-children" />
                        </span>
                        Rename
                      </div>
                    </>
                  }
                </div>
              }
            </>
          }
        </div>
        {item.type === 'file' &&
          <div className={classNames('mt-1', classes.elContent)}>
            <div className={classNames('is-flex is-align-items-center is-justify-content-center', classes.elContentInner)}>
              {item.contentType === "application/pdf" ?
                <span className="icon big-icon">
                  <i className="mdi mdi-file-pdf default" />
                </span>
              :
                <img src={`${REACT_APP_SITE_API}/${item.thumbsURL}`} alt={item.name} onError={onErrorLoadImg}/>
              }              
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default MediaItem;