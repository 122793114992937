import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import firebase from 'firebase.js';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

const { REACT_APP_SITE_API } = process.env;

export const COMPANIES_FETCH_DATA_INIT = createAction('COMPANIES_FETCH_DATA_INIT');
export const COMPANIES_FETCH_DATA_SUCCESS = createAction(
  'COMPANIES_FETCH_DATA_SUCCESS'
);
export const COMPANIES_FETCH_DATA_FAIL = createAction('COMPANIES_FETCH_DATA_FAIL');

export const COMPANIES_DELETE_COMPANY_INIT = createAction('COMPANIES_DELETE_COMPANY_INIT');
export const COMPANIES_DELETE_COMPANY_SUCCESS = createAction(
  'COMPANIES_DELETE_COMPANY_SUCCESS'
);
export const COMPANIES_DELETE_COMPANY_FAIL = createAction('COMPANIES_DELETE_COMPANY_FAIL');

export const COMPANIES_CREATE_COMPANY_INIT = createAction('COMPANIES_CREATE_COMPANY_INIT');
export const COMPANIES_CREATE_COMPANY_SUCCESS = createAction(
  'COMPANIES_CREATE_COMPANY_SUCCESS'
);
export const COMPANIES_CREATE_COMPANY_FAIL = createAction('COMPANIES_CREATE_COMPANY_FAIL');

export const COMPANIES_MODIFY_COMPANY_INIT = createAction('COMPANIES_MODIFY_COMPANY_INIT');
export const COMPANIES_MODIFY_COMPANY_SUCCESS = createAction(
  'COMPANIES_MODIFY_COMPANY_SUCCESS'
);
export const COMPANIES_MODIFY_COMPANY_FAIL = createAction('COMPANIES_MODIFY_COMPANY_FAIL');

export const COMPANIES_CLEAN_UP = createAction('COMPANIES_CLEAN_UP');

export const COMPANIES_CLEAR_DATA_LOGOUT = createAction('COMPANIES_CLEAR_DATA_LOGOUT');

export const fetchCompanies = (companyId = '') => {
  return async (dispatch, getState) => {
    dispatch(COMPANIES_FETCH_DATA_INIT());

    if (companyId) {
      let company;
      try {
        company = await fetchDocument('companies', companyId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(COMPANIES_FETCH_DATA_FAIL({ error }));
      }

      if (!company) {
        const errorMessage = 'Company not available';
        toastr.error('', errorMessage);
        return dispatch(COMPANIES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const companies = getState().companies.data;
      companies.push(company);

      return dispatch(
        COMPANIES_FETCH_DATA_SUCCESS({
          data: companies
        })
      );
    }

    let companies;

    try {
      companies = await fetchCollection('companies');
    } catch (error) {
      return dispatch(COMPANIES_FETCH_DATA_FAIL({ error }));
    }

    if (!companies) {
      const errorMessage = 'Companies not found';
      toastr.info('', errorMessage);
      return dispatch(COMPANIES_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      COMPANIES_FETCH_DATA_SUCCESS({
        data: companies
      })
    );
  };
};

export const deleteCompany = (id) => {
  return async (dispatch, getState) => {
    dispatch(COMPANIES_DELETE_COMPANY_INIT());

    const { locale } = getState().preferences;

    try {
      await deleteDocument('companies', id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COMPANIES_DELETE_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The company was deleted.');
    return dispatch(COMPANIES_DELETE_COMPANY_SUCCESS({ id }));
  };
};

export const clearCompaniesDataLogout = () => {
  return (dispatch) => {
    dispatch(COMPANIES_CLEAR_DATA_LOGOUT());
  };
};

export const createCompany = (data) => {
  return async (dispatch, getState) => {
    dispatch(COMPANIES_CREATE_COMPANY_INIT());

    const { locale } = getState().preferences;
    const companies = getState().companies.data;
    const { name } = data;

    const existCompany = companies.find((companyItem) => companyItem.name === name );
    if (existCompany) {
      const errorMessage = `Company with the name "${name}" exist!`;

      toastr.error('', errorMessage);
      return dispatch(
        COMPANIES_MODIFY_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    let response;
    
    const companyData = {...data};
    delete companyData.id;

    try {
      response = await addDocument('companies', companyData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COMPANIES_CREATE_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Company created successfully');
    return dispatch(COMPANIES_CREATE_COMPANY_SUCCESS({ company: {
        id: response.id,
        ...companyData
      }
    }));
  };
};

export const modifyCompany = (data) => {
  return async (dispatch, getState) => {
    dispatch(COMPANIES_MODIFY_COMPANY_INIT());
    const { locale } = getState().preferences;
    const companies = getState().companies.data;

    const { id, name } = data;

    const existCompany = companies.filter((companyItem) => companyItem.id !== id).find((companyItem) => companyItem.name === name );
    if (existCompany) {
      const errorMessage = `Company with the name "${name}" exist!`;

      toastr.error('', errorMessage);
      return dispatch(
        COMPANIES_MODIFY_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    const companyData = {...data};
    delete companyData.id;
    
    try {
      await updateDocument('companies', id, companyData);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COMPANIES_MODIFY_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Company updated successfully');
    return dispatch(COMPANIES_MODIFY_COMPANY_SUCCESS({ company: companyData, id }));
  };
};

export const companiesCleanUp = () => (dispatch) => dispatch(COMPANIES_CLEAN_UP());

export const generateTokenCompany = (id, code) => {
  return async (dispatch, getState) => {
    dispatch(COMPANIES_MODIFY_COMPANY_INIT());
    const { locale } = getState().preferences;

    const company = getState().companies.data.find(item => item.id === id);
    try {
      const generateToken = firebase
        .functions()
        .httpsCallable('httpsGenerateTokenCompany');

      const response = await generateToken(code);
      if (company && response && response.data) {
        const userInfo = JSON.parse(Buffer.from(response.data.id_token.split('.')[1], 'base64').toString());
        if (userInfo.email) {
          company.google_account = userInfo.email;
        }

        company.refresh_token = response.data.refresh_token;
        company.access_token = response.data.access_token;
        await updateDocument('companies', id, company);
      }
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', 'Error Generate Token');
      return dispatch(
        COMPANIES_MODIFY_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Token generated successfully');
    return dispatch(COMPANIES_MODIFY_COMPANY_SUCCESS({ company, id, redirect: false }));
  };
};

export const updateTokenCompany = (id, refreshToken) => {
  return async (dispatch, getState) => {
    dispatch(COMPANIES_MODIFY_COMPANY_INIT());
    const { locale } = getState().preferences;

    const company = getState().companies.data.find(item => item.id === id);
    try {
      const updateToken = firebase
        .functions()
        .httpsCallable('httpsUpdateTokenCompany');

      const response = await updateToken(refreshToken);
      if (company && response && response.data) {
        company.refresh_token = response.data.refresh_token;
        company.access_token = response.data.access_token;
        await updateDocument('companies', id, company);
      }
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', 'Error Update Token');
      return dispatch(
        COMPANIES_MODIFY_COMPANY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Token updated successfully');
    return dispatch(COMPANIES_MODIFY_COMPANY_SUCCESS({ company, id, redirect: false }));
  };
};

export const getCompanyFiles = async (path, companies) => {
  const storageRef = firebase.storage().ref();
  const folderPath = path.replace('Storage', '');
  const listRef = storageRef.child(folderPath);

  const resources = {
    folder: [],
    files: []
  };

  try{
    const response = await listRef.listAll();

    response.prefixes.forEach((folderRef) => {
      if (folderRef.name !== 'thumbs') {
        const folderPathArr = folderRef.fullPath.split('/');
        const companyName = folderPathArr[0];

        const companyData = companies.find(company => company.name === companyName);
        if (companyData) {
          resources.folder.push({
            type: 'folder',
            name: folderRef.name,
            fullPath: folderRef.fullPath,
            parent: folderRef.parent.fullPath
          });
        }
      }
    });

    const promises = [];
    response.items.filter(item => item.name !== '.ghostfile').forEach((itemRef) => {
      promises.push(itemRef.getMetadata());

      const filePathArr = itemRef.fullPath.split('/');
      const companyName = filePathArr[0];
      const companyData = companies.find(company => company.name === companyName);
      if (companyData) {
        const filePath = filePathArr.slice(1);
        filePath.unshift('companies', companyData.id, 'images');
        const fileFullPath = filePath.join('/');
        const thumbsFolderPath = fileFullPath.replace(itemRef.name, '');
        const thumbsFile = itemRef.name.split('.');
        const thumbsFileName = `${thumbsFile[0]}_100x135.${thumbsFile[1]}`;
        const thumbsPath = `${thumbsFolderPath}thumbs/${thumbsFileName}`;

        resources.files.push({
          type: 'file',
          name: itemRef.name,
          fullPath: fileFullPath,
          realPath: itemRef.fullPath,
          thumbsURL: thumbsPath,
          parent: itemRef.parent.fullPath
        });
      }
    });

    const filesMetadata = await Promise.all(promises);

    filesMetadata.forEach((fileRef) => {
      const { fullPath } = fileRef;
      const foundIndex = resources.files.findIndex(file => file.realPath === fullPath);

      if (foundIndex >=0) {
        resources.files[foundIndex] = {
          ...fileRef,
          ...resources.files[foundIndex]
        };
      }
    });
  }
  catch (error){
    toastr.error('', 'Error');

  }

  return resources;
};

export const deleteFile = async (path) => {
  const storageRef = firebase.storage().ref();
  const filePath = path.replace('Storage', '');
  const fileRef = storageRef.child(filePath);

  try{
    const metaData = await fileRef.getMetadata();
    if (metaData.contentType.includes('image')) {
      const thumbsFolderPath = fileRef.fullPath.replace(fileRef.name, '');
      const thumbsFile = fileRef.name.split('.');
      const thumbsFileName = `${thumbsFile[0]}_100x135.${thumbsFile[1]}`;
      const thumbsPath = `${thumbsFolderPath}thumbs/${thumbsFileName}`;
      const thumbsFileRef = storageRef.child(thumbsPath);
      await thumbsFileRef.delete();
    }

    await fileRef.delete();
  }
  catch (error){
    console.log(error);
  }
};

export const deleteCompanyFile = async (path) => {
  try{
    await deleteFile(path);
    toastr.info('', 'File Deleted');
  }
  catch (error){
    console.log(error);
    toastr.error('', 'File Not Deleted');
  }
};

export const renameCompanyFile = async (newName, file) => {
  const storageRef = firebase.storage().ref();
  const oldFileServerPath = `${REACT_APP_SITE_API}/${file.fullPath}`;

  try{
    const oldFileExtension = file.name.split('.')[1];
    const newFilePath = file.realPath.replace(file.name, `${newName}.${oldFileExtension}`);
    const newFileRef = storageRef.child(newFilePath);

    const oldFile = await fetch(oldFileServerPath, {
      method: 'GET'
    });

    if (oldFile.status === 200) {
      const oldFileBlob = await oldFile.blob();
      await newFileRef.put(oldFileBlob);
      await deleteFile(file.realPath);
    }
  }
  catch (error){
    toastr.error('', 'File Not Renamed');
  }
};

export const uploadCompanyFile = async (file, path) => {
  try{
    const filePath = path.replace('Storage', '');
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${filePath}/${file.name}`);
    const response = await fileRef.put(file);

    if (response.state !== 'success') {
      toastr.error('', 'File Not Uploaded');
    }
  }
  catch (error){
    toastr.error('', 'File Not Uploaded');
  }
};

export const uploadFile = async (file, path) => {
  const response = {
    name: file.name,
    status: 'error',
    msg: 'Upload error'
  };

  try{
    const filePath = path.replace('Storage', '');
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${filePath}/${file.name}`);
    const responseUpload = await fileRef.put(file);

    if (responseUpload.state === 'success') {
      response.status = 'success';
      response.msg = 'Upload successful';
    }
  }
  catch (error){
    response.status = 'error';
  }

  return response;
};

export const addCompanyFolder = async (path) => {
  try{
    const filePath = path.replace('Storage', '');
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(`${filePath}/.ghostfile`);
    const response = await fileRef.putString('');

    if (response.state !== 'success') {
      toastr.error('', "Folder isn't created");
    }
  }
  catch (error){
    toastr.error('', "Folder isn't created");
  }
};

export const deleteCompanyFolder = async (path) => {
  const storageRef = firebase.storage().ref();
  const folderPath = path.replace('Storage', '');
  const listRef = storageRef.child(folderPath);

  try{
    const response = await listRef.listAll();

    const folderPromises = [];
    response.prefixes.forEach((folderRef) => {
      folderPromises.push(deleteCompanyFolder(folderRef.fullPath));
    });

    await Promise.all(folderPromises);
    
    const filePromises = [];

    response.items.forEach((fileRef) => {
      filePromises.push(fileRef.delete());
    });

    await Promise.all(filePromises);
  }
  catch (error){
    toastr.error('', 'Error');
  }
};