import { createReducer } from 'redux-act';

import {
  MEDIA_FETCH_DATA_INIT,
  MEDIA_FETCH_DATA_SUCCESS,
  MEDIA_FETCH_DATA_FAIL,
  MEDIA_DELETE_MEDIA_ITEM_INIT,
  MEDIA_DELETE_MEDIA_ITEM_SUCCESS,
  MEDIA_DELETE_MEDIA_ITEM_FAIL,
  MEDIA_CREATE_MEDIA_ITEM_INIT,
  MEDIA_CREATE_MEDIA_ITEM_SUCCESS,
  MEDIA_CREATE_MEDIA_ITEM_FAIL,
  MEDIA_MODIFY_MEDIA_ITEM_INIT,
  MEDIA_MODIFY_MEDIA_ITEM_SUCCESS,
  MEDIA_MODIFY_MEDIA_ITEM_FAIL,
  MEDIA_CLEAN_UP,
  MEDIA_CLEAR_DATA_LOGOUT,
} from 'state/actions/media';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const mediaReducer = createReducer(
  {
    [MEDIA_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MEDIA_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [MEDIA_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [MEDIA_DELETE_MEDIA_ITEM_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_DELETE_MEDIA_ITEM_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [MEDIA_DELETE_MEDIA_ITEM_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_CREATE_MEDIA_ITEM_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_CREATE_MEDIA_ITEM_SUCCESS]: (state, { mediaItem }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(mediaItem),
        loading: false,
        error: null,
        success: true,
      });
    },
    [MEDIA_CREATE_MEDIA_ITEM_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_MODIFY_MEDIA_ITEM_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MEDIA_MODIFY_MEDIA_ITEM_SUCCESS]: (state, { id, mediaItem }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  id,
                  name: mediaItem.name,
                  size: mediaItem.size,
                  type: mediaItem.type
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [MEDIA_MODIFY_MEDIA_ITEM_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MEDIA_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [MEDIA_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
