import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const MEDIA_FETCH_DATA_INIT = createAction('MEDIA_FETCH_DATA_INIT');
export const MEDIA_FETCH_DATA_SUCCESS = createAction(
  'MEDIA_FETCH_DATA_SUCCESS'
);
export const MEDIA_FETCH_DATA_FAIL = createAction('MEDIA_FETCH_DATA_FAIL');

export const MEDIA_DELETE_MEDIA_ITEM_INIT = createAction('MEDIA_DELETE_MEDIA_ITEM_INIT');
export const MEDIA_DELETE_MEDIA_ITEM_SUCCESS = createAction(
  'MEDIA_DELETE_MEDIA_ITEM_SUCCESS'
);
export const MEDIA_DELETE_MEDIA_ITEM_FAIL = createAction('MEDIA_DELETE_MEDIA_ITEM_FAIL');

export const MEDIA_CREATE_MEDIA_ITEM_INIT = createAction('MEDIA_CREATE_MEDIA_ITEM_INIT');
export const MEDIA_CREATE_MEDIA_ITEM_SUCCESS = createAction(
  'MEDIA_CREATE_MEDIA_ITEM_SUCCESS'
);
export const MEDIA_CREATE_MEDIA_ITEM_FAIL = createAction('MEDIA_CREATE_MEDIA_ITEM_FAIL');

export const MEDIA_MODIFY_MEDIA_ITEM_INIT = createAction('MEDIA_MODIFY_MEDIA_ITEM_INIT');
export const MEDIA_MODIFY_MEDIA_ITEM_SUCCESS = createAction(
  'MEDIA_MODIFY_MEDIA_ITEM_SUCCESS'
);
export const MEDIA_MODIFY_MEDIA_ITEM_FAIL = createAction('MEDIA_MODIFY_MEDIA_ITEM_FAIL');

export const MEDIA_CLEAN_UP = createAction('MEDIA_CLEAN_UP');

export const MEDIA_CLEAR_DATA_LOGOUT = createAction('MEDIA_CLEAR_DATA_LOGOUT');

export const fetchMedia = (mediaItemId = '') => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_FETCH_DATA_INIT());

    const observeMediaItem = (updatedMediaItem) => {
      if(!updatedMediaItem){
        return dispatch(
          MEDIA_DELETE_MEDIA_ITEM_SUCCESS({
            id: mediaItemId
          })
        );
      }

      return dispatch(
        MEDIA_MODIFY_MEDIA_ITEM_SUCCESS({
          id: mediaItemId,
        })
      );
    };

    if (mediaItemId) {
      let mediaItem;
      try {
        mediaItem = await fetchDocument('media', mediaItemId, observeMediaItem);
      } catch (error) {
        toastr.error('', error);
        return dispatch(MEDIA_FETCH_DATA_FAIL({ error }));
      }

      if (!mediaItem) {
        const errorMessage = 'MediaItem not available';
        toastr.error('', errorMessage);
        return dispatch(MEDIA_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const media = getState().media.data;
      media.push(mediaItem);

      return dispatch(
        MEDIA_FETCH_DATA_SUCCESS({
          data: media
        })
      );
    }

    let media;

    const observeMedia = (updatedMedia) => {
      return dispatch(
        MEDIA_FETCH_DATA_SUCCESS({
          data: updatedMedia,
        })
      );
    };

    try {
      const queryOptions = {queries: []};
      media = await fetchCollection('media', queryOptions, observeMedia);
    } catch (error) {
      return dispatch(MEDIA_FETCH_DATA_FAIL({ error }));
    }

    if (!media) {
      const errorMessage = 'Media not found';
      toastr.info('', errorMessage);
      return dispatch(MEDIA_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      MEDIA_FETCH_DATA_SUCCESS({
        data: media
      })
    );
  };
};

export const deleteMediaItem = (id) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_DELETE_MEDIA_ITEM_INIT());

    const { locale } = getState().preferences;

    try {
      await deleteDocument('media', id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_DELETE_MEDIA_ITEM_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The mediaItem was deleted.');
    return dispatch(MEDIA_DELETE_MEDIA_ITEM_SUCCESS({ id }));
  };
};

export const clearMediaDataLogout = () => {
  return (dispatch) => {
    dispatch(MEDIA_CLEAR_DATA_LOGOUT());
  };
};


export const createMediaItem = (data) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_CREATE_MEDIA_ITEM_INIT());

    const { locale } = getState().preferences;
    const mediaItemData = {...data};
    let response;

    try {
      response = await addDocument('media', mediaItemData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_CREATE_MEDIA_ITEM_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'MediaItem created successfully');
    return dispatch(MEDIA_CREATE_MEDIA_ITEM_SUCCESS({ mediaItem: {
        id: response.id,
        ...mediaItemData
      }
    }));
  };
};

export const modifyMediaItem = (data) => {
  return async (dispatch, getState) => {
    dispatch(MEDIA_MODIFY_MEDIA_ITEM_INIT());

    const { locale } = getState().preferences;
    const { id } = data;

    const mediaItemData = {...data};
    delete mediaItemData.id;
    
    try {
      await updateDocument('media', id, mediaItemData);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        MEDIA_MODIFY_MEDIA_ITEM_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'MediaItem updated successfully');
    return dispatch(MEDIA_MODIFY_MEDIA_ITEM_SUCCESS({ mediaItem: mediaItemData, id }));
  };
};

export const mediaCleanUp = () => (dispatch) => dispatch(MEDIA_CLEAN_UP());