/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useFormatMessage } from 'hooks';
import { fetchDevices, devicesCleanUp, deleteDevice } from 'state/actions/devices';
import { checkAccess } from 'permissions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';
import './UserDevices.scss';

const UserDevices = ({ userId }) => {
  const { devices, loading } = useSelector(
    (state) => ({
      devices: state.devices?.data?.filter(item => item.user_id === userId),
      loading: state.devices.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDevices());

    return () => {
      dispatch(devicesCleanUp());
    };
  }, [dispatch]);

  const deviceDeleteHandler = (deviceId) => {
    dispatch(deleteDevice(deviceId));
  };

  const title = useFormatMessage('UserForm.devices');
  const unlinkBtn = useFormatMessage('UserForm.unlink');
  const accessDeleteDevices = checkAccess('delete device');

  return (
    <div className={classNames('tile is-ancestor', { 'devices-loading': loading })}>
      <div className="tile is-parent">
        <div className="card tile is-child">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-cellphone default" />
              </span>
              {title}
            </p>
          </header>
          <div className="card-content">
            <table className="table">
              <tbody>
                {devices && devices.length > 0 ? (
                  devices.map((item) => (
                      <tr key={item.id}>
                        <td>{item.device_id}</td>
                        {accessDeleteDevices &&
                          <td><button onClick={() => deviceDeleteHandler(item.id)} className="button is-danger">{unlinkBtn}</button></td>
                        }
                      </tr>
                    )
                  ))
                  : 
                  (
                    <tr>
                      <td>
                        <p>Devices not found</p>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>  
  );
};

export default UserDevices;