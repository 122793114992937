import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import Companies from 'pages/Companies';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Company from 'pages/Company';
import Media from 'pages/Media';
import paths from './paths';
import PrivateRoute from './PrivateRoute';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute path={paths.ADD_USER} component={User} rule="create user"/>
        <PrivateRoute path={paths.MODIFY_USER} component={User} rule="edit user"/>
        <PrivateRoute path={paths.USERS} component={Users} rule="view user list"/>
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.ADD_COMPANY} component={Company} rule="create company"/>
        <PrivateRoute path={paths.MODIFY_COMPANY} component={Company} rule="view company"/>
        <PrivateRoute path={paths.COMPANIES} component={Companies} rule="view companies list"/>
        <PrivateRoute path={paths.MEDIA} component={Media} rule="view media"/>
        <PrivateRoute path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
