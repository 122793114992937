import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const DEVICES_FETCH_DATA_INIT = createAction('DEVICES_FETCH_DATA_INIT');
export const DEVICES_FETCH_DATA_SUCCESS = createAction(
  'DEVICES_FETCH_DATA_SUCCESS'
);
export const DEVICES_FETCH_DATA_FAIL = createAction('DEVICES_FETCH_DATA_FAIL');

export const DEVICES_DELETE_DEVICE_INIT = createAction('DEVICES_DELETE_DEVICE_INIT');
export const DEVICES_DELETE_DEVICE_SUCCESS = createAction(
  'DEVICES_DELETE_DEVICE_SUCCESS'
);
export const DEVICES_DELETE_DEVICE_FAIL = createAction('DEVICES_DELETE_DEVICE_FAIL');

export const DEVICES_CREATE_DEVICE_INIT = createAction('DEVICES_CREATE_DEVICE_INIT');
export const DEVICES_CREATE_DEVICE_SUCCESS = createAction(
  'DEVICES_CREATE_DEVICE_SUCCESS'
);
export const DEVICES_CREATE_DEVICE_FAIL = createAction('DEVICES_CREATE_DEVICE_FAIL');

export const DEVICES_MODIFY_DEVICE_INIT = createAction('DEVICES_MODIFY_DEVICE_INIT');
export const DEVICES_MODIFY_DEVICE_SUCCESS = createAction(
  'DEVICES_MODIFY_DEVICE_SUCCESS'
);
export const DEVICES_MODIFY_DEVICE_FAIL = createAction('DEVICES_MODIFY_DEVICE_FAIL');

export const DEVICES_CLEAN_UP = createAction('DEVICES_CLEAN_UP');

export const DEVICES_CLEAR_DATA_LOGOUT = createAction('DEVICES_CLEAR_DATA_LOGOUT');

export const fetchDevices = (deviceId = '') => {
  return async (dispatch, getState) => {
    dispatch(DEVICES_FETCH_DATA_INIT());

    if (deviceId) {
      let device;
      try {
        device = await fetchDocument('devices', deviceId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(DEVICES_FETCH_DATA_FAIL({ error }));
      }

      if (!device) {
        const errorMessage = 'Device not available';
        toastr.error('', errorMessage);
        return dispatch(DEVICES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const devices = getState().devices.data;
      devices.push(device);

      return dispatch(
        DEVICES_FETCH_DATA_SUCCESS({
          data: devices
        })
      );
    }

    let devices;

    try {
      devices = await fetchCollection('devices');
    } catch (error) {
      return dispatch(DEVICES_FETCH_DATA_FAIL({ error }));
    }

    if (!devices) {
      const errorMessage = 'Devices not found';
      toastr.info('', errorMessage);
      return dispatch(DEVICES_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      DEVICES_FETCH_DATA_SUCCESS({
        data: devices
      })
    );
  };
};

export const deleteDevice = (id) => {
  return async (dispatch, getState) => {
    dispatch(DEVICES_DELETE_DEVICE_INIT());

    const { locale } = getState().preferences;

    try {
      await deleteDocument('devices', id);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        DEVICES_DELETE_DEVICE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The device was deleted.');
    return dispatch(DEVICES_DELETE_DEVICE_SUCCESS({ id }));
  };
};

export const clearDevicesDataLogout = () => {
  return (dispatch) => {
    dispatch(DEVICES_CLEAR_DATA_LOGOUT());
  };
};


export const createDevice = (data) => {
  return async (dispatch, getState) => {
    dispatch(DEVICES_CREATE_DEVICE_INIT());

    const { locale } = getState().preferences;
    const deviceData = {...data};
    let response;

    try {
      response = await addDocument('devices', deviceData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        DEVICES_CREATE_DEVICE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Device created successfully');
    return dispatch(DEVICES_CREATE_DEVICE_SUCCESS({ device: {
        id: response.id,
        ...deviceData
      }
    }));
  };
};

export const modifyDevice = (data) => {
  return async (dispatch, getState) => {
    dispatch(DEVICES_MODIFY_DEVICE_INIT());

    const { locale } = getState().preferences;
    const { id } = data;

    const deviceData = {...data};
    delete deviceData.id;
    
    try {
      await updateDocument('devices', id, deviceData);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        DEVICES_MODIFY_DEVICE_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Device updated successfully');
    return dispatch(DEVICES_MODIFY_DEVICE_SUCCESS({ device: deviceData, id }));
  };
};

export const devicesCleanUp = () => (dispatch) => dispatch(DEVICES_CLEAN_UP());